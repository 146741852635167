export const mutations = {
  setToken (state, value) {
    state.accessToken = value
  },
  setUserReference (state, value) {
    state.userReference = value
  },
  setDisplayName (state, value) {
    state.displayName = value
  },
  setUserProfileImageUrl (state, value) {
    state.photoURL = value
  },
  setUserTrackingProfileStatus (state, value) {
    state.userTrackingProfileStatus = value
  }
}
