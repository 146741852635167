var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',[(this.smallscreen)?_c('a-col',{staticClass:"login-left",attrs:{"span":24}},[_c('a-row',[_c('a-col',{attrs:{"span":4}}),_c('a-col',{staticClass:"font-mobile login-width-mobile login-left-width",attrs:{"span":18}},[_c('img',{staticClass:"login-logo-mobile",attrs:{"src":require('../../assets/dashmote-logo-white.svg'),"alt":""}}),_c('div',[_c('div',{staticClass:"header-1",staticStyle:{"margin-top":"75px","margin-bottom":"100px"}},[_vm._v(" Reset your password here. ")])]),_c('a-row',{staticClass:"header-4"},[_vm._v("Please enter your new password")]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'newPassword',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your new password!',
                      }, // eslint-disable-next-line no-tabs
											{ min: 8, message: 'Password must be minimum 8 characters.' } ],
                  } ]),expression:"[\n                  'newPassword',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please input your new password!',\n                      }, // eslint-disable-next-line no-tabs\n\t\t\t\t\t\t\t\t\t\t\t{ min: 8, message: 'Password must be minimum 8 characters.' },\n                    ],\n                  },\n                ]"}],ref:"pwd",staticStyle:{"padding":"16px"},attrs:{"type":"password","placeholder":"New password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login($event)}}})],1),_c('a-form-item',{attrs:{"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'newPasswordConfirm',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your new password!',
                      }, // eslint-disable-next-line no-tabs
											{ min: 8, message: 'Password must be minimum 8 characters.' } ],
                  } ]),expression:"[\n                  'newPasswordConfirm',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please confirm your new password!',\n                      }, // eslint-disable-next-line no-tabs\n\t\t\t\t\t\t\t\t\t\t\t{ min: 8, message: 'Password must be minimum 8 characters.' },\n                    ],\n                  },\n                ]"}],ref:"pwdConfirm",staticStyle:{"padding":"16px"},attrs:{"type":"password","placeholder":"Confirm new password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login($event)}}})],1)],1),_c('a-row',[_c('span',{staticClass:"login-help-font",on:{"click":function($event){return _vm.$intercom.show()}}},[_vm._v("I need some help")])]),_c('a-button',{staticClass:"primary-button-style-total row-space-16",staticStyle:{"width":"100%","top":"16px","bottom":"16px","margin-bottom":"140px"},on:{"click":_vm.login}},[_vm._v("Reset Password")]),_c('div',{staticClass:"copyright-text-mobile"},[_vm._v("© Dashmote 2021")])],1),_c('a-col',{attrs:{"span":2}})],1)],1):_c('a-col',{staticClass:"login-left",attrs:{"span":12}},[_c('img',{staticClass:"login-logo",attrs:{"src":require('../../assets/dashmote-logo.svg'),"alt":""}}),_c('a-row',[_c('a-col',{attrs:{"span":4}}),_c('a-col',{staticClass:"font-mobile login-width-mobile login-left-width",attrs:{"span":18}},[_c('div',[_c('div',{staticClass:"header-1 mobile-margin-top title-top",staticStyle:{"margin-bottom":"55px"}},[_vm._v(" Reset your password here. ")])]),_c('a-row',{staticClass:"header-4"},[_vm._v("Please enter your new password")]),_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'newPassword',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your new password!',
                      }, // eslint-disable-next-line no-tabs
											{ min: 8, message: 'Password must be minimum 8 characters.' } ],
                  } ]),expression:"[\n                  'newPassword',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please input your new password!',\n                      }, // eslint-disable-next-line no-tabs\n\t\t\t\t\t\t\t\t\t\t\t{ min: 8, message: 'Password must be minimum 8 characters.' },\n                    ],\n                  },\n                ]"}],ref:"pwd",staticStyle:{"padding":"16px"},attrs:{"type":"password","placeholder":"New password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login($event)}}})],1),_c('a-form-item',{attrs:{"has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'newPasswordConfirm',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your new password!',
                      }, // eslint-disable-next-line no-tabs
											{ min: 8, message: 'Password must be minimum 8 characters.' } ],
                  } ]),expression:"[\n                  'newPasswordConfirm',\n                  {\n                    rules: [\n                      {\n                        required: true,\n                        message: 'Please confirm your new password!',\n                      }, // eslint-disable-next-line no-tabs\n\t\t\t\t\t\t\t\t\t\t\t{ min: 8, message: 'Password must be minimum 8 characters.' },\n                    ],\n                  },\n                ]"}],ref:"pwdConfirm",staticStyle:{"padding":"16px"},attrs:{"type":"password","placeholder":"Confirm new password"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login($event)}}})],1)],1),_c('a-row',[_c('span',{staticClass:"login-help-font",on:{"click":function($event){return _vm.$intercom.show()}}},[_vm._v("I need some help")])]),_c('a-button',{staticClass:"primary-button-style-total row-space-16 reset-password-button",staticStyle:{"width":"100%","top":"16px","bottom":"16px"},on:{"click":_vm.login}},[_vm._v("Reset Password")])],1),_c('a-col',{attrs:{"span":2}})],1),_c('div',{staticClass:"copyright-text"},[_vm._v("© Dashmote 2021")])],1),_c('a-col',{staticClass:"login-right-block",attrs:{"span":12}},[_c('div',{staticClass:"top-all-picture"}),_c('div',{staticClass:"bottom-bgc"})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }