<template>
<div>
  <a-row>
    <!-- For small screen sizes under 980px width / mobile -->
    <a-col v-if="this.smallscreen" class="login-left" :span="24">
      <a-row>
        <a-col :span="4"></a-col>
        <a-col
          :span="18"
          class="font-mobile login-width-mobile login-left-width"
        >
        <img class="login-logo-mobile" :src="require('../../assets/dashmote-logo-white.svg')" alt="">
          <div>
            <div class="header-1" style="margin-top: 75px; margin-bottom: 100px">
              Reset your password here.
            </div>
          </div>
          <a-row class="header-4">Please enter your new password</a-row>
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item has-feedback>
              <a-input style="padding:16px;"
                v-decorator="[
                  'newPassword',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your new password!',
                      }, // eslint-disable-next-line no-tabs
											{ min: 8, message: 'Password must be minimum 8 characters.' },
                    ],
                  },
                ]"
                type="password"
                placeholder="New password"
                ref="pwd"
                @keyup.enter="login"
              />
            </a-form-item>
            <a-form-item has-feedback>
              <a-input style="padding:16px;"
                v-decorator="[
                  'newPasswordConfirm',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your new password!',
                      }, // eslint-disable-next-line no-tabs
											{ min: 8, message: 'Password must be minimum 8 characters.' },
                    ],
                  },
                ]"
                type="password"
                placeholder="Confirm new password"
                ref="pwdConfirm"
                @keyup.enter="login"
              />
            </a-form-item>

          </a-form>
          <a-row>
            <span @click="$intercom.show()" class="login-help-font">I need some help</span>
          </a-row>
          <a-button
            class="primary-button-style-total row-space-16"
            style="width: 100%; top: 16px; bottom: 16px; margin-bottom: 140px"
            @click="login"
            >Reset Password</a-button
          >
          <div class="copyright-text-mobile">© Dashmote 2021</div>
        </a-col>
        <a-col :span="2"></a-col>
      </a-row>
    </a-col>

    <!-- For normal screen sizes above 980px width / desktop -->
    <a-col v-else class="login-left" :span="12">
      <img class="login-logo" :src="require('../../assets/dashmote-logo.svg')" alt="">
      <a-row>
        <a-col :span="4"></a-col>
        <a-col
          :span="18"
          class="font-mobile login-width-mobile login-left-width"
        >
          <div>
            <div class="header-1 mobile-margin-top title-top" style="margin-bottom: 55px;">
              Reset your password here.
            </div>
          </div>
          <a-row class="header-4">Please enter your new password</a-row>
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item has-feedback>
              <a-input style="padding:16px;"
                v-decorator="[
                  'newPassword',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your new password!',
                      }, // eslint-disable-next-line no-tabs
											{ min: 8, message: 'Password must be minimum 8 characters.' },
                    ],
                  },
                ]"
                type="password"
                placeholder="New password"
                ref="pwd"
                @keyup.enter="login"
              />
            </a-form-item>
            <a-form-item has-feedback>
              <a-input style="padding:16px;"
                v-decorator="[
                  'newPasswordConfirm',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your new password!',
                      }, // eslint-disable-next-line no-tabs
											{ min: 8, message: 'Password must be minimum 8 characters.' },
                    ],
                  },
                ]"
                type="password"
                placeholder="Confirm new password"
                ref="pwdConfirm"
                @keyup.enter="login"
              />
            </a-form-item>

          </a-form>
          <a-row>
            <span @click="$intercom.show()" class="login-help-font">I need some help</span>
          </a-row>
          <a-button
            class="primary-button-style-total row-space-16 reset-password-button"
            style="width: 100%; top: 16px; bottom: 16px;"
            @click="login"
            >Reset Password</a-button
          >
        </a-col>
        <a-col :span="2"></a-col>
      </a-row>
       <div class="copyright-text">© Dashmote 2021</div>
    </a-col>
    <!-- Right side of desktop login page -->
    <a-col class="login-right-block" :span="12">
            <div class="top-all-picture"></div>
      <div class="bottom-bgc"></div>
    </a-col>
  </a-row>
</div>

</template>
<script>
import firebase from 'firebase'
const mobile = require('is-mobile')
export default {
  name: 'Login',
  data () {
    return {
      mobile,
      mode: '',
      actionCode: '',
      continueUrl: '',
      lang: '',
      smallscreen: window.innerWidth <= 980,
      email: ''
    }
  },
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: 'register' })
  },

  created () {
    var self = this
    addEventListener('DOMContentLoaded', function () {
      // TODO: Implement getParameterByName()
      function getParameterByName (name, url = window.location.href) {
        name = name.replace(/[[\]]/g, '\\$&')
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
        var results = regex.exec(url)
        if (!results) return null
        if (!results[2]) return ''
        return decodeURIComponent(results[2].replace(/\+/g, ' '))
      }

      // Get the action to complete.
      self.mode = getParameterByName('mode')
      // Get the one-time code from the query parameter.
      self.actionCode = getParameterByName('oobCode')
      // (Optional) Get the continue URL from the query parameter if available.
      self.continueUrl = getParameterByName('continueUrl')
      // (Optional) Get the language code if available.
      self.lang = getParameterByName('lang') || 'en'
    }, false)
    // Check if the client is using a mobile device or not, based on the screen width.
    addEventListener('resize', () => {
      this.smallscreen = innerWidth <= 980
    })
  },

  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    },
    goToLogin () {
      this.$router.push('/login')
    },
    handleResetPassword (auth, actionCode, continueUrl, lang) {
      // Localize the UI to the selected language as determined by the lang parameter
      // Verify the password reset code is valid.
      var self = this

      const newPassword = self.$refs.pwd.stateValue
      const newPasswordConfirm = self.$refs.pwdConfirm.stateValue
      console.log(newPassword + newPasswordConfirm)
      if (newPassword !== '') {
        if (newPassword.length >= 8) {
          if (newPassword === newPasswordConfirm) {
            auth.verifyPasswordResetCode(actionCode).then(function (email) {
              auth.confirmPasswordReset(actionCode, newPassword).then(function (resp) {
                self.succesMessage()
              }).catch(function (error) {
                console.log(error)
                self.$error({
                  title: 'The code might have expired or the password is too weak, please try again!'
                })
              })
            }).catch(function (error) {
              console.log(error)
              self.$error({
                title: 'Invalid or expired action code. please try to reset the password again!'
              })
            })
          } else {
            // passwords don't match
            self.$error({
              title: 'The passwords do not match! Please try again'
            })
          }
        } else {
        // password should have atleast 8 characters
          self.$error({
            title: 'The password should have atleast 8 characters! Please try again'
          })
        }
      } else {
        // password cannot be empty
        self.$error({
          title: 'The password cannot be empty!'
        })
      }
    },

    errorMessage (errorText) {
      this.$message.error(errorText)
    },
    succesMessage () {
      this.$message.success({ content: 'Sucess! Going back to login...' })
      setTimeout(() => {
        this.goToLogin()
      }, 1000)
    },
    login () {
      var auth = firebase.auth()

      // Handle the user management action.
      switch (this.mode) {
        case 'resetPassword':
          // Display reset password handler and UI.
          console.log('mode: Reset Password. actionCode: ' + this.actionCode)
          this.handleResetPassword(auth, this.actionCode, this.continueUrl, this.lang)
          break
        default:
          this.$error({
            title: 'There is no code provided for password reset, please try to reset the password again!'
          })
      // Error: invalid mode.
      }
    }
  }
}
</script>
<style scoped>
@import "./Login.css";
@import "./LoginMobile.css";
@import "../Projects/Projects.css";
@import "../Dashboards/DetailPage.css";
</style>
