<template>
  <div class="project-wrapper">
    <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
    <a-row class="row-space-16">
      <div class="page-navigation"><span @click="goMainPage" class="project-overview-link"><img style="margin-right: 8px;" :src="require('../../assets/arrowLeft.svg')"/><span style="border-bottom: 1px solid #1990FF;">All Workspaces</span></span></div>
    </a-row>
    <a-col :span="24">
    <a-row class="row-space-32">
      <a-tabs type="card" @change="callback">
        <a-tab-pane key="1" tab="Dashboards">
          <Dashboard />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Users">
          <Users />
        </a-tab-pane>
        <a-tab-pane key="3" tab="Activity">
          <Activity />
        </a-tab-pane>
      </a-tabs>
    </a-row>
    </a-col>
  </div>
</template>

<script>
// import { db } from '@/firebase'
import Dashboard from './Dashboard.vue'
import Users from './Users.vue'
import Activity from './Activity.vue'

export default {
  name: 'DetailPage',
  props: {
    msg: String
  },
  components: {
    Dashboard,
    Users,
    Activity
  },
  data () {
    return {
      overlay: false
    }
  },
  methods: {
    goMainPage () {
      this.$router.push('/')
    },
    callback (key) {
      // Switch for keeping track of the currently opened tab.
      switch (key) {
        case '1' :
          this.$mixpanel.track('Open dashboard tab')
          break
        case '2' :
          this.$mixpanel.track('Open users tab')
          break
        case '3' :
          this.$mixpanel.track('Open activity tab')
          break
      }
    }
  },
  mounted () {
    // Give a callback for the open dashboard tab user analytics.
    this.callback('1')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../Projects/Projects.css';
@import './DetailPage.css';
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
