<template>
   <a-row class="main-area">
     <a-modal class="dashCustomModal" v-model="dashModalFileUploadedVisible" :title="dashModalFileUploadTitle">
      <div slot="closeIcon"><img :src="require('../../../assets/setupstepicon/closeModalCross.svg')"/></div>
      <div class="dashCustomModalText">You can now access it in the data room. <br/>Note: It will also be visible to your delivery lead.</div>
      <template slot="footer">
        <a-button class="confirmButton" key="submit" type="primary" @click="handleDashModalFileUploaded">
          Ok, I get it!
        </a-button>
      </template>
    </a-modal>
    <a-modal class="dashCustomModal" v-model="dashModalStepSubmittedVisible" :title="dashModalStepSubmittedTitle">
      <div slot="closeIcon"><img :src="require('../../../assets/setupstepicon/closeModalCross.svg')"/></div>
      <div class="dashCustomModalText">Your delivery lead will review this task soon. <br/>Keep an eye on your email!</div>
      <template slot="footer">
        <a-button class="confirmButton" key="submit" type="primary" @click="handleDashModalSubmitStep">
          Ok, I get it!
        </a-button>
      </template>
    </a-modal>
    <!-- Main code -->
      <a-col class="content">
         <a-row class="row-space-24">
            <div class="dashboard-overview-setup-steps-content">
               <a-collapse @change="trackCollapse($event)" accordion expand-icon-position="right" :bordered="false" style="background: #F2F6FC;">
                  <a-collapse-panel
                     class="active-step"
                     v-for="(item, index) of itemsList"
                     :style="customStyle"
                     :key="String(item.orderID)"
                     >
                     <div slot="header" style="position: absolute; margin-left: -168px; margin-top: 12px;">
                        <a-tooltip v-if="item.status === 'open'" title="Please read information and submit required step">
                           <img style="margin-bottom: 2px; width: 24px; height: 24px;" :src="require('../../../assets/setupstepicon/OpenIcon.svg')"/>
                           <span class="status-text" style="margin-left: 16px;">To be done</span>
                        </a-tooltip>
                        <a-tooltip v-if="item.status === 'action'" title="Please can you review our comments and submit the required changes">
                           <img style="margin-bottom: 2px; width: 24px; height: 24px;" :src="require('../../../assets/setupstepicon/ActionIcon.svg')"/>
                           <span class="status-text" style="margin-left: 16px;">Action Needed</span>
                        </a-tooltip>
                        <a-tooltip v-if="item.status === 'done'" title="All good here, nothing to be done anymore">
                           <img style="margin-bottom: 2px; width: 24px; height: 24px;" :src="require('../../../assets/setupstepicon/DoneIcon.svg')"/>
                           <span class="status-text-completed" style="margin-left: 16px;">Completed</span>
                        </a-tooltip>
                        <a-tooltip v-if="item.status === 'submitted'" title="Dashmote is reviewing your information, nothing to be done from your side">
                           <img style="margin-bottom: 2px; width: 24px; height: 24px;" :src="require('../../../assets/setupstepicon/IconSubmitted.svg')"/>
                           <span class="status-text" style="margin-left: 16px;">Submitted</span>
                        </a-tooltip>
                     </div>
                     <div v-if="(item.status ==='open' || item.status ==='submitted' || item.status ==='action') " slot="header" style="margin-left:12px; margin-top:12px;" v-html="item.title"></div>
                     <div v-if="item.status === 'done'" slot="header" style="margin-left:12px; margin-top:12px; font-family: Roboto; font-style: normal; font-weight: 500;font-size: 14px; line-height: 24px;color: #989BAC;" v-html="item.title"></div>
                     <v-md-preview style="margin-left: 12px" :text="item.bodyText"></v-md-preview>
                     <div class="uploadSetupStep" v-if="item.hasUploadButton">
                        <a-upload
                            v-if="item.hasAgreeButton && (item.status ==='open' || item.status ==='action') "
                           style="height: 32px; margin-left:12px; position: relative;"
                           :customRequest="dummyRequest"
                           :multiple="true"
                           :file-list="item.fileList"
                           :before-upload="beforeUpload"
                           @change="handleChange(index, $event)"
                           >
                           <a-button class="third-button-style-total"><img class="buttonIcon" style="margin-bottom: 2px; height: 16px; width 16px; margin-right: 8px;" :src="require('../../../assets/setupstepicon/upload-icon.svg')"/>Upload files</a-button>
                        </a-upload>
                        <a-button v-if="item.hasAgreeButton && (item.status ==='submitted' || item.status ==='done') " disabled class="primary-button-disabled"><img class="buttonIcon" style="margin-bottom: 2px; height: 16px; width 16px; margin-right: 8px;" :src="require('../../../assets/setupstepicon/upload-icon.svg')"/>Upload files</a-button>
                            <span v-if="item.hasAgreeButton && (item.status ==='open' || item.status ==='action') " style="margin-right: calc(100% - 600px); margin-left:16px;/* font-family: Roboto; font-style: normal; font-weight: normal; font-size: 14px; line-height: 22px; color: #989BAC;">Max 300MB</span>
                            <span v-if="item.hasAgreeButton && (item.status ==='submitted' || item.status ==='done') " style="margin-right: calc(100% - 620px); margin-left:16px;/* font-family: Roboto; font-style: normal; font-weight: normal; font-size: 14px; line-height: 22px; color: #989BAC;">Max 300MB</span>
                            <span class="learn-more-text" @click="goToLink('http://help.dashmote.com/en/articles/5234165-client-data-protection')">Learn more</span><span class="about-our-policy-text">about our security policy</span>
                            <a-button v-if="item.hasAgreeButton && (item.status ==='open' || item.status ==='action') " class="primary-button-style-total" @click="submitStep(index, item.id)" style="margin-left: 24px; margin-bottom: 12px;margin-right:10px;"><img class="buttonIcon" style="height: 16px; width 16px; margin-right: 8px;margin-bottom: 2px;" :src="require('../../../assets/setupstepicon/check.svg')"/>Submit</a-button>
                            <a-button v-if="item.hasAgreeButton && (item.status ==='submitted' || item.status ==='done') " disabled class="primary-button-disabled" style="margin-left: 24px; margin-bottom: 12px;margin-right:10px;"><img class="buttonIcon" style="height: 16px; width 16px; margin-right: 8px; margin-bottom: 2px;" :src="require('../../../assets/setupstepicon/check.svg')"/>Submitted</a-button>
                     </div>
                     <a-button v-if="item.embedURL" class="third-button-style-total" @click="openBigger(item.embedURL, item.id, item.status, item.hasAgreeButton)" style="margin-left: 20px; float:right; margin-bottom: 10px; margin-right:10px;"><img style="margin-bottom:4px;margin-right:4px;" class="buttonIcon" width="12px;" :src="require('../../../assets/setupstepicon/expand-outline.svg')"/>Fullscreen</a-button>
                     <iframe v-if="item.embedURL" class="airtable-embed" :src="item.embedURL" frameborder="0" onmousewheel="" width="97%" height="300px" style="background: transparent; margin-left: 12px; border: 1px solid #ccc;"></iframe>
                     <div v-if="item.embedURL" style="white-space: pre-wrap; display: flex; align-items: center;justify-content: space-between; margin-top: 12px;">
                        <div style="margin-left: 10px;"><span class="learn-more-text" @click="goToLink('http://help.dashmote.com/en/articles/5234165-client-data-protection')">Learn more</span><span class="about-our-policy-text">about our security policy</span></div>
                        <a-button v-if="item.hasAgreeButton && !item.hasUploadButton && (item.status ==='open' || item.status ==='action') " class="primary-button-style-total" @click="submitStep(index, item.id)" style="margin-right:10px;"><img class="buttonIcon" style="height: 16px; width 16px; margin-right: 8px;margin-bottom: 2px;" :src="require('../../../assets/setupstepicon/check.svg')"/>Submit</a-button>
                        <a-button v-if="item.hasAgreeButton && (item.status ==='submitted' || item.status ==='done') " disabled class="primary-button-disabled" style="margin-right:10px;"><img class="buttonIcon" style="height: 16px; width 16px; margin-right: 8px;margin-bottom: 2px; " :src="require('../../../assets/setupstepicon/check.svg')"/>Submitted</a-button>
                     </div>
                     <a-modal v-if="item.embedURL" v-model="visible" title="" width=80% @ok="handleOk(index, item.id, $event)" destroyOnClose>
                       <iframe class="airtable-embed" :src="modalURL" frameborder="0" onmousewheel="" width="100%" style="background: transparent; border: 1px solid #ccc; margin-top:20px; height: 65vh;"></iframe>
                        <template slot="footer">
                           <a-button key="back" class="third-button-style-total" @click="handleCancel">
                              Close
                           </a-button>
                           <a-button v-if="modalHasAgreeButton && (modalStatus ==='open' || modalStatus ==='action') " key="submit" type="primary" class="primary-button-style-total" @click="handleOk(index, item.id, $event)">
                              Submit
                           </a-button>
                        </template>
                     </a-modal>
                  </a-collapse-panel>
               </a-collapse>
            </div>
         </a-row>
      </a-col>
   </a-row>
</template>

<script>
import { db } from '../../../firebase'
import moment from 'moment'
import axios from 'axios'
var CryptoJS = require('crypto-js')
const AWS = require('aws-sdk')
const s3 = new AWS.S3({
  accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.VUE_APP_AWS_REGION,
  ACL: 'public-read'
})

export default {
  name: 'DashboardOverview',
  data () {
    return {
      overlay: false,
      modalURL: '',
      modalID: '',
      modalStatus: '',
      modalHasAgreeButton: '',
      visible: false,
      HTMLcontent: null,
      dashboardName: '',
      itemsList: [],
      hasOpenSteps: false,
      text: 'We need you to agree on the brand list. Please check the list below and click on Agree.  ',
      customStyle:
        'margin-bottom: 24px; background: #FFFFFF; max-height600px; box-sizing: border-box; box-shadow: 0px 4px 10px rgba(120, 120, 120, 0.1), 0px 4px 5px rgba(151, 151, 151, 0.1); min-height:70px; border-radius: 8px; float: right; min-width:635px; width: calc(100% - 205px); margin-left: 205px;',
      dashModalFileUploadedVisible: false,
      dashModalFileUploadTitle: 'Congratulations! \nYour file is uploaded.',
      dashModalStepSubmittedVisible: false,
      dashModalStepSubmittedTitle: 'Congratulations! \nYour progress is saved!'
    }
  },
  methods: {
    trackCollapse (event) {
      if (typeof event !== 'undefined') {
        this.$mixpanel.track('Opened task', { openTime: moment().format(), taskTitle: this.itemsList[event - 1].title, embedURL: this.itemsList[event - 1].embedURL, hasUploadButton: this.itemsList[event - 1].hasUploadButton, dashboard: this.dashboardName })
      }
    },
    destroyAllModals () {
      this.Modal.destroyAll()
    },
    goToLink (sourceURL) {
      window.open(sourceURL, '_blank')
      // Tracking
      this.$mixpanel.track('Clicked learn more about Data Privacy Policy', { linkURL: sourceURL })
    },
    showDashModalFileUploaded () {
      this.dashModalFileUploadedVisible = true
    },
    handleDashModalFileUploaded (e) {
      console.log(e)
      this.dashModalFileUploadedVisible = false
    },

    showDashModalSubmitStep () {
      this.dashModalStepSubmittedVisible = true
    },
    handleDashModalSubmitStep (e) {
      console.log(e)
      this.dashModalStepSubmittedVisible = false
    },

    dummyRequest ({ file, onSuccess }) {
      setTimeout(() => {
        onSuccess('ok')
      }, 0)
    },
    handleChange (index, info) {
      let fileList = [...info.fileList]

      // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new
      fileList = fileList.slice(-2)

      // 2. read from response and show file link
      fileList = fileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url
        }
        return file
      })
      this.itemsList[index].fileList = fileList
    },
    // Allowed is: CSV, JSON, TSV, SHEET (Excel, Google), DOC (Word, Google), PDF, ZIP, GZIP, Powerpoint(PPT,PPTX)
    validateFiletype (filetype) {
      if (
        filetype === 'application/excel' ||
        filetype === 'application/vnd.ms-excel' ||
        filetype === 'application/x-excel' ||
        filetype === 'application/x-msexcel' ||
        filetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        filetype === 'application/doc' ||
        filetype === 'application/ms-doc' ||
        filetype === 'application/msword' ||
        filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        filetype === 'application/gzip' ||
        filetype === 'application/zip' ||
        filetype === 'text/tab-separated-values' ||
        filetype === 'application/pdf' ||
        filetype === 'application/json' ||
        filetype === 'text/csv' ||
        filetype === 'application/mspowerpoint' ||
        filetype === 'application/powerpoint' ||
        filetype === 'application/vnd.ms-powerpoint' ||
        filetype === 'application/x-mspowerpoint' ||
        filetype === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ) {
        return true
      } else {
        return false
      }
    },
    beforeUpload (file) {
      const self = this
      const correctFileType = this.validateFiletype(file.type)
      const isLt = file.size / 1024 / 1024 < 300
      // Is the filetype supported?
      if (!correctFileType) {
        this.$message.error('Unsupported filetype. Allowed is: CSV, JSON, TSV, EXCEL, WORD, POWERPOINT, PDF, ZIP or GZIP')
        axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
          text: `User tried to upload unsupported filetype
            User: ${self.getDecryptedEmail()}
            Project:  https://app.dashmote.com/project/${this.$route.params.projectId}
            Dashboard Name: ${this.dashboardName}
      
            Filename: ${file.name}
            Unsupported Filetype: ${file.type}
            Filesize: ${Math.round(file.size / 1024 / 1024)} MB`
        }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      } else {
        // If Filetype is supported check size of the file
        if (!isLt) {
          this.$message.error('File must be smaller than 300M')
          axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
            text: `User tried to upload file larger than 300MB
              User: ${self.getDecryptedEmail()}
              Project:  https://app.dashmote.com/project/${this.$route.params.projectId}
              Dashboard Name: ${this.dashboardName}
      
              Filename: ${file.name}
              Filetype: ${file.type}
              Filesize: ${Math.round(file.size / 1024 / 1024)} MB`
          }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        }
      }
      if (isLt && correctFileType) {
        this.uploadS3File(file)
      }
    },
    makeid (length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    uploadS3File (file) {
      const self = this
      const fileExtention = file.name.split('.')[1]
      const madeID = this.makeid(10)
      const fileKey = madeID + '.' + fileExtention
      console.log('upload file')
      const params = {
        Bucket: 'dash-frontend-dev/DATAROOM/' + this.$route.params.projectId + '/' + this.$route.params.dashboardId, // pass your bucket name
        Key: fileKey, // file will be saved as test.png
        Body: file
      }

      s3.upload(params, function (s3Err, data) {
        if (s3Err) throw s3Err
        self.setDataroomItem(madeID, file.name, fileKey, file.type, data.Location)
        // console.log(`File uploaded successfully at ${data.Location}`)
        self.showDashModalFileUploaded()
      })
    },
    setDataroomItem (id, filename, filekey, filetype, fileLocation) {
      const self = this
      var dataroomItemData = {
        filename: filename,
        status: 'submitted',
        uploader: this.getDecryptedEmail(),
        timestamp: moment().format('MMM Do YYYY'),
        filekey: filekey,
        filetype: filetype,
        fileLocation: fileLocation
      }
      this.$mixpanel.track('Uploaded File', { filename: filename, filetype: filetype, projectId: this.$route.params.projectId, dashboardId: this.$route.params.dashboardId })
      this.fireUploadSlackNotification(filename, filekey, filetype, fileLocation)
      this.fireToSlackApp('fileUploaded', 1, filename, fileLocation)
      db.ref(`projects/${self.$route.params.projectId}/dashboards/${self.$route.params.dashboardId}/settings/dataroom/${id}`).update(dataroomItemData)
        .then(() => {
          console.log('success')
          return 'successfully added to DB'
        })
        .catch((error) => {
          console.log('ERROR', error.toString())
        })
    },
    fireUploadSlackNotification (filename, filekey, filetype, fileLocation) {
      const self = this
      axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
        text: `User just uploaded a new file to his dataroom from My Tasks
      User: ${self.getDecryptedEmail()}
      Project:  https://app.dashmote.com/project/${this.$route.params.projectId}
      Dashboard Name: ${this.dashboardName}
      Dashboard url: https://app.dashmote.com/project/${this.$route.params.projectId}/${this.$route.params.dashboardId}
      Settings url: https://app.dashmote.com/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings
      
      Filename: ${filename}
      S3 Filekey: ${filekey}
      S3 Location: ${fileLocation}
      Filetype: ${filetype}`
      }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    },
    showModal (url, id, status, hasAgreeButton) {
      this.modalURL = url
      this.modalID = id
      this.modalStatus = status
      this.modalHasAgreeButton = hasAgreeButton
      this.visible = true
    },
    handleCancel (e) {
      this.visible = false
    },
    handleOk (index, id) {
      this.submitStep(index, id)
      this.visible = false
    },
    submitStep (id, actualId) {
      // console.log('submitted step: ' + id + 1)
      this.itemsList[id].status = 'submitted'
      this.fireSlackNotification(id)
      this.fireToSlackApp('taskSubmitted', id)
      this.setStatus(actualId, 'submitted')
      this.$mixpanel.track('Submitted task', { submitTime: moment().format(), taskTitle: this.itemsList[id].title, dashboard: this.dashboardName })
      window.Intercom('trackEvent', 'submitted-task')
      this.showDashModalSubmitStep()
    },
    setStatus (id, status) {
      const self = this
      var statusData = {
        status: status
      }
      db.ref(`projects/${self.$route.params.projectId}/dashboards/${self.$route.params.dashboardId}/settings/setupsteps/${id}`).update(statusData)
        .then(() => {
          // console.log('success')
          self.$emit('check-for-open-task')
          return 'success'
        })
        .catch((error) => {
          console.log('ERROR', error.toString())
        })
    },
    fireSlackNotification (stepId) {
      const self = this
      axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
        text: `User just submitted a setup step
      User: ${self.getDecryptedEmail()}
      Project:  https://app.dashmote.com/project/${this.$route.params.projectId}
      Dashboard Name: ${this.dashboardName}
      Dashboard url: https://app.dashmote.com/project/${this.$route.params.projectId}/${this.$route.params.dashboardId}
      Step title: ${this.itemsList[stepId].title}
      Settings url: https://app.dashmote.com/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings`
      }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    },
    fireToSlackApp (messageType, id, filename, fileLocation) {
      const slackAppHook = 'https://hooks.slack.com/services/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'

      if (messageType === 'taskSubmitted') {
        axios.post(slackAppHook,
          this.formatSubmitTaskSlackMessage(id), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      } else if (messageType === 'fileUploaded') {
        axios.post(slackAppHook,
          this.formatUploadFileSlackMessage(filename, fileLocation), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      }
    },
    formatSubmitTaskSlackMessage (id) {
      return `{
"blocks": [{
    "type": "header",
    "text": {
      "type": "plain_text",
      "text": "User just submitted a task",
      "emoji": true
    }
  },
  {
  "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "*Dashboard Name:*  ${this.dashboardName}\n *Task Title:* ${this.itemsList[id].title}\n *User:* ${this.getDecryptedEmail()}"
    }
  },
  {
    "type": "divider"
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "Go to Dashboard Management in the Admin Application ->"
    },
    "accessory": {
      "type": "button",
      "text": {
        "type": "plain_text",
        "text": "Open Admin App",
        "emoji": true
      },
      "value": "open_dashboard_management_admin",
      "url": "https://admin.getdashmote.com/dashboard/detail/${this.$route.params.projectId}/settings/${this.$route.params.dashboardId}"
    }
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "Go to task in the Client Application ->"
    },
    "accessory": {
      "type": "button",
      "text": {
        "type": "plain_text",
        "text": "Open Client App",
        "emoji": true
      },
      "value": "open_dashboard_management_tasks_client",
      "url": "https://app.dashmote.com/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings/tasks"
    }
  },
  {
    "type": "divider"
  }
]}`
    },
    formatUploadFileSlackMessage (filename, fileLocation) {
      return `{
"blocks": [
  {
    "type": "header",
    "text": {
      "type": "plain_text",
      "text": "User just uploaded a file to the dataroom",
      "emoji": true
    }
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "*Dashboard Name:*  ${this.dashboardName}\n *Filename:* ${filename}\n *User:* ${this.getDecryptedEmail()}"
    }
  },
  {
    "type": "divider"
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "*S3 Location:* ${fileLocation}"
    }
  },
  {
    "type": "divider"
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "Go to Dashboard Management in the Admin Application ->"
    },
    "accessory": {
      "type": "button",
      "text": {
        "type": "plain_text",
        "text": "Open Admin App",
        "emoji": true
      },
      "value": "open_dashboard_management_admin",
      "url": "https://admin.getdashmote.com/dashboard/detail/${this.$route.params.projectId}/settings/${this.$route.params.dashboardId}"
    }
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "Go to Dashboard Management in the Client Application ->"
    },
    "accessory": {
      "type": "button",
      "text": {
        "type": "plain_text",
        "text": "Open Client App",
        "emoji": true
      },
      "value": "open_dashboard_management_client",
      "url": "https://app.dashmote.com/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings"
    }
  },
  {
    "type": "divider"
  }
]
}`
    },
    openBigger (url, id, status, hasAgreeButton) {
      this.showModal(url, id, status, hasAgreeButton)
    },
    getDecryptedEmail () {
      // Check if there is already an userEmail stored
      if (localStorage.getItem('userEmail')) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem('userEmail')), process.env.VUE_APP_AES_PASSPHRASE).toString()
        localStorage.setItem('userReference', ciphertext)
        localStorage.removeItem('userEmail')
      }
      // decrypt item
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userReference'), process.env.VUE_APP_AES_PASSPHRASE)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    },
    sortByOrderID () {
      this.itemsList.sort((a, b) => (a.orderID > b.orderID) ? 1 : -1)
    },
    getData () {
      const self = this
      db.ref('projects/' + self.$route.params.projectId + '/dashboards/' + self.$route.params.dashboardId).once('value', snapshot => {
        self.dashboardName = snapshot.val().name
        if (snapshot.val().settings) {
          for (var step in snapshot.val().settings.setupsteps) {
            const stepValue = snapshot.val().settings.setupsteps[step]
            // console.log(step)
            self.itemsList.push({ id: step, orderID: stepValue.orderID, title: stepValue.title, status: stepValue.status, previewText: stepValue.previewText, bodyText: stepValue.bodyText, embedURL: stepValue.embedURL, hasAgreeButton: stepValue.hasAgreeButton, hasUploadButton: stepValue.hasUploadButton, fileList: [] })
          }
          self.sortByOrderID()
        }
      })
    }
  },
  mounted () {
    // Mounted state here.
    this.getData()
  }
}
</script>

<style scoped>
@import './DashboardSettings.css';
</style>
