<template>
  <div style="width:100%;">
  </div>
</template>

<script>
import { db } from '@/firebase'

export default {
  name: 'PowerbiPage',
  data () {
    return {
    }
  },
  methods: {
    redirectToRightDashboard () {
      const self = this
      // Redirect.
      db.ref(`projects/${self.$route.params.projectId}/dashboards/${self.$route.params.dashboardId}`).once('value', snapshot => {
        if (snapshot.val().url) {
          const url = snapshot.val().url
          if (url.startsWith('https://columbo') || url.startsWith('https://colombo')) {
            self.$router.push(`/project/${self.$route.params.projectId}/columbo/${self.$route.params.dashboardId}`)
          } else if (url.startsWith('https://app.powerbi.com')) {
            self.$router.push(`/project/${self.$route.params.projectId}/forge/${self.$route.params.dashboardId}`)
          }
        } else {
          if (snapshot.val().settings) {
            self.$router.push(`/project/${self.$route.params.projectId}/dashboard/${self.$route.params.dashboardId}/settings`)
          }
          console.log(snapshot.val())
        }
      })
    }
  },
  mounted () {
    this.redirectToRightDashboard()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
