<template>
    <a-row class="main-area">
      <a-modal class="dashCustomModal" v-model="dashModalFileUploadedVisible" :title="dashModalFileUploadTitle">
      <div slot="closeIcon"><img :src="require('../../../assets/setupstepicon/closeModalCross.svg')"/></div>
      <div class="dashCustomModalText">You can now access it in the data room. <br/>Note: It will also be visible to your project manager.</div>
      <template slot="footer">
        <a-button class="confirmButton" key="submit" type="primary" @click="handleDashModalFileUploaded">
          Ok, I get it!
        </a-button>
      </template>
    </a-modal>
    <!-- Main Content -->
        <a-col class="content">
          <div class="mydataroom-search">
          <div class="search-style"><a-input-search
            placeholder="Search on file name"
            style="float: right;font-weight: normal;"
            class="project-input-search"
            @change="onSearch"
          /></div></div>
            <a-row class="row-space-24">
                <div class="dashboard-overview-mydataroom">
                    <a-list :grid="{ gutter: 16, column: columnsize }" :data-source="filterData">
                        <a-list-item slot="renderItem" slot-scope="item">
                            <a-card v-if="!item.uploadCard" style="width:100%;" class="data-card" :key="String(item.id)">
                                <div style="white-space: nowrap;">
                                    <span class="data-card-title">
                                      <a-tooltip :title=item.title>
                                        <span class="title-text">{{item.title}}</span>
                                      </a-tooltip>
                                      <!-- State will never be shown, to be removed later -->
                                      <a-tooltip v-if="false" title="Sorry! You don’t have permission to download.">
                                        <img style="float: right; margin-right: -16px; height: 18px; width 14px;" :src="require('../../../assets/setupstepicon/download_icon_disabled.svg')" />
                                      </a-tooltip>
                                      <!-- Until here -->
                                      <img v-else-if="item.downloadable" style="float: right; margin-right: -16px; height: 18px; width 14px; cursor: pointer;" :src="require('../../../assets/setupstepicon/download_icon_active.svg')" @click="getS3File (item.url, item.title)" />
                                    <div style="font-family: Roboto;font-style: normal;font-weight: 500;font-size: 14px;line-height: 14px;letter-spacing: 0.005em;color: #989BAC; white-space: nowrap; text-overflow: ellipsis; width: 100%; display: block; overflow: hidden">{{item.uploader}}</div></span></div>
                                    <div style="margin-top:8px; margin-left: -8px; margin-right: -8px;"><img v-if="item.filetype === 'application/pdf'" :src="require('../../../assets/setupstepicon/pdf.svg')" />
                                         <img v-else-if="item.filetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'" :src="require('../../../assets/setupstepicon/excel.svg')" />
                                         <img v-else :src="require('../../../assets/setupstepicon/unknown.svg')" />
                                      <div style="float: right">{{item.timestamp}}</div>
                                    </div>
                            </a-card>
                            <a-upload-dragger v-else name="file" :before-upload="beforeUpload" :show-upload-list="false" :multiple="false" action="https://www.mocky.io/v2/5cc8019d300000980a055e76" @change="handleChange">
                                <a-card class="upload-card border" :bordered="false">
                                    <center style="margin-top: -8px;"><div class="uploadButton"/>
                                        <div><span class="drag-drop-text">Drag and drop</span><span class="browse-files-text"> or browse files</span></div>
                                        <div class="max-size-text">Max file size 300MB</div>
                                    </center>
                                </a-card>
                            </a-upload-dragger>
                        </a-list-item>
                    </a-list>
                </div>
            </a-row>
        </a-col>
    </a-row>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import { db } from '../../../firebase'
const R = require('ramda')
var CryptoJS = require('crypto-js')
const AWS = require('aws-sdk')
const s3 = new AWS.S3({
  accessKeyId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.VUE_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.VUE_APP_AWS_REGION,
  ACL: 'public-read'
})

export default {
  name: 'DashboardOverview',
  data () {
    return {
      overlay: false,
      columnsize: 4,
      dashboardName: '',
      itemsList: [],
      hasOpenSteps: false,
      search: '',
      dashModalFileUploadedVisible: false,
      dashModalFileUploadTitle: 'Congratulations! \nYour file is uploaded.'
    }
  },
  methods: {
    showDashModalFileUploaded () {
      this.dashModalFileUploadedVisible = true
    },
    handleDashModalFileUploaded (e) {
      console.log(e)
      this.dashModalFileUploadedVisible = false
    },
    onSearch (e) {
      this.search = e.target.value
    },
    isContain (item) {
      if (this.search && this.search !== undefined) {
        let isRight
        try {
          isRight = item.title.toLowerCase().includes(this.search.toLowerCase())
        } catch (err) {
          console.log(err)
        }
        return isRight
      } else {
        return true
      }
    },
    handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
      }
    },
    // Allowed is: CSV, JSON, TSV, SHEET (Excel, Google), DOC (Word, Google), PDF, ZIP, GZIP, Powerpoint(PPT,PPTX)
    validateFiletype (filetype) {
      if (
        filetype === 'application/excel' ||
        filetype === 'application/vnd.ms-excel' ||
        filetype === 'application/x-excel' ||
        filetype === 'application/x-msexcel' ||
        filetype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        filetype === 'application/doc' ||
        filetype === 'application/ms-doc' ||
        filetype === 'application/msword' ||
        filetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        filetype === 'application/gzip' ||
        filetype === 'application/zip' ||
        filetype === 'text/tab-separated-values' ||
        filetype === 'application/pdf' ||
        filetype === 'application/json' ||
        filetype === 'text/csv' ||
        filetype === 'application/mspowerpoint' ||
        filetype === 'application/powerpoint' ||
        filetype === 'application/vnd.ms-powerpoint' ||
        filetype === 'application/x-mspowerpoint' ||
        filetype === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
      ) {
        return true
      } else {
        return false
      }
    },
    beforeUpload (file) {
      const self = this
      const correctFileType = this.validateFiletype(file.type)
      const isLt = file.size / 1024 / 1024 < 300
      // Is the filetype supported?
      if (!correctFileType) {
        this.$message.error('Unsupported filetype. Allowed is: CSV, JSON, TSV, EXCEL, WORD, PDF, ZIP or GZIP')
        axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
          text: `User tried to upload unsupported filetype
            User: ${self.getDecryptedEmail()}
            Project:  https://app.dashmote.com/project/${this.$route.params.projectId}
            Dashboard Name: ${this.dashboardName}
      
            Filename: ${file.name}
            Unsupported Filetype: ${file.type}
            Filesize: ${Math.round(file.size / 1024 / 1024)} MB`
        }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      } else {
        // If Filetype is supported check size of the file
        if (!isLt) {
          this.$message.error('File must be smaller than 300M')
          axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
            text: `User tried to upload file larger than 300MB
              User: ${self.getDecryptedEmail()}
              Project:  https://app.dashmote.com/project/${this.$route.params.projectId}
              Dashboard Name: ${this.dashboardName}
      
              Filename: ${file.name}
              Filetype: ${file.type}
              Filesize: ${Math.round(file.size / 1024 / 1024)} MB`
          }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        }
      }
      if (isLt && correctFileType) {
        this.uploadS3File(file)
      }
    },
    makeid (length) {
      var result = ''
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      var charactersLength = characters.length
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
    uploadS3File (file) {
      const self = this
      const fileExtention = file.name.split('.')[1]
      const madeID = this.makeid(10)
      const fileKey = madeID + '.' + fileExtention
      console.log('upload file')
      const params = {
        Bucket: 'dash-frontend-dev/DATAROOM/' + this.$route.params.projectId + '/' + this.$route.params.dashboardId, // pass your bucket name
        Key: fileKey, // file will be saved as test.png
        Body: file
      }

      s3.upload(params, function (s3Err, data) {
        if (s3Err) throw s3Err
        self.setDataroomItem(madeID, file.name, fileKey, file.type, data.Location)
        // console.log(`File uploaded successfully at ${data.Location}`)
        self.showDashModalFileUploaded()
      })
    },

    getS3File (key, title) {
      const self = this
      // const self = this
      var params = {
        Bucket: 'dash-frontend-dev/DATAROOM/' + this.$route.params.projectId + '/' + this.$route.params.dashboardId,
        Key: key
      }
      s3.getObject(params, function (err, data) {
        if (err) console.log(err, err.stack) // an error occurred
        else {
          // console.log(data) // successful response
          // self.download(key, data.Body)
          const blob = new Blob([data.Body], { type: data.ContentType })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = title
          link.click()
          self.sendDownloadSlackNotification(title)
        }
      })
    },
    sendDownloadSlackNotification (key) {
      const self = this
      axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
        text: `User downloaded a file from his dataroom
      User: ${self.getDecryptedEmail()}
      Project:  https://app.dashmote.com/project/${this.$route.params.projectId}
      Dashboard Name: ${this.dashboardName}
      Dashboard url: https://app.dashmote.com/project/${this.$route.params.projectId}/${this.$route.params.dashboardId}
      Settings url: https://app.dashmote.com/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings
      
      Filekey: ${key}`
      }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    },
    getDecryptedEmail () {
      // Check if there is already an userEmail stored
      if (localStorage.getItem('userEmail')) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem('userEmail')), process.env.VUE_APP_AES_PASSPHRASE).toString()
        localStorage.setItem('userReference', ciphertext)
        localStorage.removeItem('userEmail')
      }
      // decrypt item
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userReference'), process.env.VUE_APP_AES_PASSPHRASE)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    },
    setDataroomItem (id, filename, filekey, filetype, fileLocation) {
      const self = this
      var dataroomItemData = {
        filename: filename,
        status: 'submitted',
        uploader: this.getDecryptedEmail(),
        timestamp: moment().format('MMM Do YYYY'),
        filekey: filekey,
        filetype: filetype,
        fileLocation: fileLocation
      }
      this.$mixpanel.track('Uploaded File', { filename: filename, filetype: filetype, projectId: this.$route.params.projectId, dashboardId: this.$route.params.dashboardId })
      this.fireSlackNotification(filename, filekey, filetype, fileLocation)
      this.fireToSlackApp('fileUploaded', 1, filename, fileLocation)
      db.ref(`projects/${self.$route.params.projectId}/dashboards/${self.$route.params.dashboardId}/settings/dataroom/${id}`).update(dataroomItemData)
        .then(() => {
          console.log('success')
          self.clearFileList()
          self.getData()
          return 'successfully added to DB'
        })
        .catch((error) => {
          console.log('ERROR', error.toString())
        })
    },
    fireSlackNotification (filename, filekey, filetype, fileLocation) {
      const self = this
      axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
        text: `User just uploaded a new file to his dataroom
      User: ${self.getDecryptedEmail()}
      Project:  https://app.dashmote.com/project/${this.$route.params.projectId}
      Dashboard Name: ${this.dashboardName}
      Dashboard url: https://app.dashmote.com/project/${this.$route.params.projectId}/${this.$route.params.dashboardId}
      Settings url: https://app.dashmote.com/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings
      
      Filename: ${filename}
      S3 Filekey: ${filekey}
      S3 Location: ${fileLocation}
      Filetype: ${filetype}`
      }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
    },
    fireToSlackApp (messageType, id, filename, fileLocation) {
      const slackAppHook = 'https://hooks.slack.com/services/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'

      if (messageType === 'fileUploaded') {
        axios.post(slackAppHook,
          this.formatUploadFileSlackMessage(filename, fileLocation), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      }
    },
    formatUploadFileSlackMessage (filename, fileLocation) {
      return `{
"blocks": [
  {
    "type": "header",
    "text": {
      "type": "plain_text",
      "text": "User just uploaded a file to the dataroom",
      "emoji": true
    }
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "*Dashboard Name:*  ${this.dashboardName}\n *Filename:* ${filename}\n *User:* ${this.getDecryptedEmail()}"
    }
  },
  {
    "type": "divider"
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "*S3 Location:* ${fileLocation}"
    }
  },
  {
    "type": "divider"
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "Go to Dashboard Management in the Admin Application ->"
    },
    "accessory": {
      "type": "button",
      "text": {
        "type": "plain_text",
        "text": "Open Admin App",
        "emoji": true
      },
      "value": "open_dashboard_management_admin",
      "url": "https://admin.getdashmote.com/dashboard/detail/${this.$route.params.projectId}/settings/${this.$route.params.dashboardId}"
    }
  },
  {
    "type": "section",
    "text": {
      "type": "mrkdwn",
      "text": "Go to Dashboard Management in the Client Application ->"
    },
    "accessory": {
      "type": "button",
      "text": {
        "type": "plain_text",
        "text": "Open Client App",
        "emoji": true
      },
      "value": "open_dashboard_management_client",
      "url": "https://app.dashmote.com/project/${this.$route.params.projectId}/dashboard/${this.$route.params.dashboardId}/settings"
    }
  },
  {
    "type": "divider"
  }
]
}`
    },
    clearFileList () {
      this.itemsList = []
    },
    getData () {
      const self = this
      db.ref('projects/' + self.$route.params.projectId + '/dashboards/' + self.$route.params.dashboardId).once('value', snapshot => {
        self.dashboardName = snapshot.val().name
        if (snapshot.val().settings) {
          for (var file in snapshot.val().settings.dataroom) {
            const fileValue = snapshot.val().settings.dataroom[file]
            self.itemsList.push({ id: file, title: fileValue.filename, status: fileValue.status, uploader: fileValue.uploader, timestamp: fileValue.timestamp, filetype: fileValue.filetype, url: fileValue.filekey, downloadable: fileValue.downloadable, fileLocation: fileValue.fileLocation })
          }
        }
      })
    }
  },
  computed: {
    // search use name
    filterData () {
      if (this.itemsList === []) {
        return [{ uploadCard: true }]
      }

      // Set the default state with uploadCard
      const basicData = [{ uploadCard: true }]

      // Filter the cards based on file title
      const filteredData = (Object.entries(R.filter(this.isContain)(this.itemsList)))
      const mappedFilteredData = filteredData.map(x => x[1])

      // Sort them based on newest timestamp first
      if (mappedFilteredData.length > 0) {
        mappedFilteredData.sort((a, b) => moment(b.timestamp, 'MMM.Do.YYYY') - moment(a.timestamp, 'MMM.Do.YYYY'))
      }

      // Add the default state and the search result together
      basicData.push(mappedFilteredData)
      return basicData.flat()
    }
  },
  mounted () {
    // Mounted state here.
    this.getData()
  },
  created () {
    if (innerWidth <= 885) {
      this.columnsize = 1
    } else if (innerWidth > 885 && innerWidth <= 1177) {
      this.columnsize = 2
    } else if (innerWidth > 1177 && innerWidth < 1477) {
      this.columnsize = 3
    } else if (innerWidth >= 1477) {
      this.columnsize = 3
    }

    addEventListener('resize', () => {
      if (innerWidth <= 885) {
        this.columnsize = 1
      } else if (innerWidth > 885 && innerWidth <= 1177) {
        this.columnsize = 2
      } else if (innerWidth > 1177 && innerWidth < 1477) {
        this.columnsize = 3
      } else if (innerWidth >= 1477) {
        this.columnsize = 3
      }
    })
  }
}
</script>

<style scoped>
@import './DashboardSettings.css';
</style>
