<template>
<!-- header may never be shown on login page, even if there is an accestoken to prevent from showing it after using the back button -->
<div v-if="this.$route.path !== '/login' && this.$route.path !== '/forgotpassword' && this.$route.path !== '/resetpassword' "> <!-- Could also be replaced with an "You are already logged in" go to home header -->
    <img style="position: fixed; margin-left: 25px; margin-top: 24px; z-index:1000; cursor: pointer;" @click="toggleParentSideMenu()" @click.stop :src="this.menuIconSource" alt="">
    <div v-if="!mobile() && !headerHiddenMode" class="header">
        <a-col :span="1">
        </a-col>
        <a-col :span="6">
            <a v-if="!sidebarOpen" class="help" @click="highlightDashmoteUniversity" style="">Improve Your Dashboard Skills Here</a>
        </a-col>
        <a-col :span="4"></a-col>
        <a-col style="position: relative; display: flex; flex-direction: row; justify-content: flex-end; margin-right:10%;">
                <div style="position: relative; cursor: pointer; margin-left: 32px; color: #223273;" @click="goSetting()">
                        <span class="ant-dropdown-link header-3">
                        {{ displayName }}
                        </span>
                    <img style="margin-left: 8px; border-radius: 50%; height: 24px; width: 24px;" @error="imageError()" :src="profilePhotoURL" />
                </div>
        </a-col>
    </div>
    <!-- Header hidden mode -->
    <div v-else-if="!mobile() && headerHiddenMode" class="headerHidden">

    </div>
    <div v-else style="height: 60px;line-height: 60px;">
        <a-col :span="2"></a-col>
        <a-col :span="2">
            <img style="position: relative; z-index:1000" @click="toggleParentSideMenu()" @click.stop :src="this.menuIconSource" alt="">
        </a-col>
        <a-col :span="14"></a-col>
        <a-col :span="2"></a-col>
        <a-col :span="2">
            <img style="margin-left: 8px; border-radius: 50%; height: 24px; width: 24px;" @error="imageError()" :src="profilePhotoURL" @click="goSetting()" />
        </a-col>
        <a-col :span="2"></a-col>
    </div>
</div>
</template>
<script>
import firebase from 'firebase'
import { db } from '@/firebase'
var CryptoJS = require('crypto-js')
const mobile = require('is-mobile')

export default {
  name: 'Header',
  data () {
    return {
      mobile,
      toggleDisable: false,
      headerHiddenMode: false,
      displayName: '',
      googlePhotoURL: require('../../../assets/icon_user.svg'),
      profilePhotoURL: require('../../../assets/icon_user.svg'),
      profilePhotoPlaceholder: require('../../../assets/icon_user.svg'),
      menuIconSource: require('../../../assets/menu-icon.svg'),
      sidebarOpen: false
    }
  },
  methods: {
    goNotifications () {
      this.$router.push('/notifications')
    },
    highlightDashmoteUniversity () {
      this.$emit('highlight-dashmote-university')
      this.toggleParentSideMenu()
    },
    toggleParentSideMenu () {
      // Switching between menu icon
      if (!mobile) {
        if (this.menuIconSource === require('../../../assets/menu-icon.svg')) {
          this.menuIconSource = require('../../../assets/menu-icon-white.svg')
          this.sidebarOpen = true
        } else {
          this.menuIconSource = require('../../../assets/menu-icon.svg')
          this.sidebarOpen = false
        }
        this.$emit('toggle-side-menu')
      } else {
        if (!this.toggleDisable) {
          if (this.menuIconSource === require('../../../assets/menu-icon.svg')) {
            this.menuIconSource = require('../../../assets/menu-icon-white.svg')
            this.sidebarOpen = true
          } else {
            this.menuIconSource = require('../../../assets/menu-icon.svg')
            this.sidebarOpen = false
          }
          this.$emit('toggle-side-menu')
        }
        this.toggleDisable = true
        // Re-enable after 0.1 seconds
        this.timeout = setTimeout(() => {
          this.toggleDisable = false
        }, 100)
      }
    },
    logOut () {
      firebase.auth().signOut()
      // Soon to be legacy email
      localStorage.removeItem('userEmail')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('displayName')
      localStorage.removeItem('userProfileImageUrl')
      localStorage.removeItem('userTrackingProfileStatus')
      localStorage.removeItem('userReference')
      this.$router.push('/login')
    },
    goSetting () {
      // Track open account settings for Mixpanel
      this.$mixpanel.track('Open account settings')
      this.$router.push('/account')
    },
    goProjects () {
      this.$router.push('/')
    },
    imageError () {
      this.profilePhotoURL = this.profilePhotoPlaceholder
    },
    updateUser (displayName, profilePhotoURL) {
      this.displayName = displayName
      this.profilePhotoURL = profilePhotoURL
    },
    hideHeader () {
      this.headerHiddenMode = true
    },
    showHeader () {
      this.headerHiddenMode = false
    },
    getProfilePhotoFromDB (uid) {
      db.ref('users/' + uid + '/').once('value', snapshot => {
        if (snapshot.val().photoURL) {
          this.profilePhotoURL = snapshot.val().photoURL
        } else {
          this.profilePhotoURL = this.googlePhotoURL
        }
      })
    },
    getDecryptedEmail () {
      // Check if there is already an userEmail stored
      if (localStorage.getItem('userEmail')) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem('userEmail')), process.env.VUE_APP_AES_PASSPHRASE).toString()
        localStorage.setItem('userReference', ciphertext)
        localStorage.removeItem('userEmail')
      }
      // decrypt item
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userReference'), process.env.VUE_APP_AES_PASSPHRASE)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    },
    getUser () {
      // console.log('got user')
      firebase.auth().onAuthStateChanged(user => {
        if (user.displayName !== null && user.displayName !== '') {
          this.displayName = user.displayName
        } else {
          this.displayName = this.getDecryptedEmail()
        }
        if (user.photoURL !== null && user.photoURL !== '') {
          this.googlePhotoURL = user.photoURL
        }
        this.getProfilePhotoFromDB(user.uid)
      })
    }
  },
  created () {
    // Get the latest userdata
    this.getUser()
  }
}
</script>
<style scoped>
@import './header.css';
@import '../../../pages/Projects/Projects.css';
</style>
