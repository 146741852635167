import Vue from 'vue'
import Vuex from 'vuex'
import { mutations } from './mutations'
import { getters } from './getters'

Vue.use(Vuex)

const state = {
  accessToken: '',
  userReference: '',
  userDisplayName: '',
  userProfileImageUrl: '',
  userTrackingProfileStatus: ''
}

const store = new Vuex.Store({
  state,
  getters,
  mutations
})

export default store
