<template>
  <div v-if="allowAccess" class="project-wrapper" style="margin-bottom: 178px;">
    <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
    <a-row class="row-space-16 settings-header">
      <div class="page-navigation"><span @click="goMainPage" class="project-overview-link"><img style="margin-right: 8px;" :src="require('../../../assets/arrowLeft.svg')"/><span style="border-bottom: 1px solid #1990FF;">Project overview</span></span><span><img style="margin-right: 8px;" :src="require('../../../assets/arrowLeft-grey.svg')"/>{{dashboardName}}</span></div>
      <div class="management-title">Dashboard Management
        <span v-if="openSetting != 'mydataroom'" class="updated-on" style="float: right;" >
          Last updated on: {{ settingsLastUpdatedAt }}
        </span>
      </div>
    </a-row>
    <a-row class="main-area">
      <a-col class="settings-menu">
        <a-row class="row-space-24">
          <div v-bind:class="{ 'dashboard-overview-menu': showHeader, 'dashboard-overview-menu-full': !showHeader, }" >
            <a-menu
              mode="inline"
              :open-keys="openKeys"
              :default-selected-keys="defaultMenuKeys"
              :key="defaultMenuKeys"
              @openChange="onOpenChange"
              @click="handleClick"
            >
              <a-menu-item key="1">
                  <span>Dashboard Overview</span>
              </a-menu-item>
              <a-menu-item key="2">
                  <span>My Tasks<span v-if="hasOpenSteps" class="dot"></span></span>
              </a-menu-item>
              <a-menu-item key="3">
                  <span>My Data Room</span>
              </a-menu-item>
            </a-menu>
            <img v-if="openSetting === 'overview'" style="position: absolute; left: 16px; bottom: 16px;" :src="require('../../../assets/setupstepicon/dashboardoverviewVisual.svg')"/>
            <img v-if="openSetting === 'mydataroom'" style="position: absolute; left: 16px; bottom: 16px;" :src="require('../../../assets/setupstepicon/dataroomVisual.svg')"/>
            <img v-if="openSetting === 'mytasks'" style="position: absolute; left: 2px; bottom: 16px;" :src="require('../../../assets/setupstepicon/setupstepsVisual.svg')"/>
          </div>
        </a-row>
      </a-col>
      <a-col class="content">
        <span v-if="openSetting === 'overview'"><DashboardOverview @show-header="toggleHeader"/></span>
        <span v-if="openSetting === 'mytasks'"><DashboardMyTasks @check-for-open-task="checkForOpenTask"/></span>
        <span v-if="openSetting === 'mydataroom'"><DashboardMyDataRoom/></span>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import DashboardOverview from './DashboardOverview.vue'
import DashboardMyTasks from './DashboardMyTasks.vue'
import DashboardMyDataRoom from './DashboardMyDataRoom.vue'

import firebase from 'firebase'
import { path } from 'ramda'
import { db } from '../../../firebase'
var CryptoJS = require('crypto-js')

export default {
  name: 'DashboardSettings',
  data () {
    return {
      showHeader: true,
      overlay: false,
      dashboardName: '',
      openSetting: 'overview',
      dashboardType: '',
      hasOpenSteps: false,
      rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
      openKeys: ['sub1'],
      defaultMenuKeys: ['1'],
      dashmoteUser: false,
      allowAccess: true,
      settingsLastUpdatedAt: 'Unavailable'
    }
  },
  components: {
    DashboardOverview,
    DashboardMyTasks,
    DashboardMyDataRoom
  },
  methods: {
    toggleHeader (showHeader) {
      if (showHeader) {
        this.showHeader = true
      } else {
        this.showHeader = false
      }
    },
    onOpenChange (openKeys) {
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
      if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
    checkForOpenTask () {
      const self = this
      db.ref('projects/' + self.$route.params.projectId + '/dashboards/' + self.$route.params.dashboardId).once('value', snapshot => {
        if (snapshot.val().settings) {
          for (var step in snapshot.val().settings.setupsteps) {
            const stepValue = snapshot.val().settings.setupsteps[step]
            if (stepValue.status === 'open') {
              self.hasOpenSteps = true
              break
            } else {
              self.hasOpenSteps = false
            }
          }
        }
      })
    },
    goMainPage () {
      this.$router.push(`/project/${this.$route.params.projectId}`)
    },
    getData () {
      const self = this
      db.ref('projects/' + self.$route.params.projectId + '/dashboards/' + self.$route.params.dashboardId).once('value', snapshot => {
        self.dashboardType = snapshot.val().productLineValue
        self.dashboardName = snapshot.val().name
        if (snapshot.val().settings) {
          if (snapshot.val().settings.lastUpdatedAt) {
            self.settingsLastUpdatedAt = snapshot.val().settings.lastUpdatedAt
          } else {
            self.settingsLastUpdatedAt = '28-06-2021'
          }
        }
      })
    },
    handleClick (e) {
      switch (e.key) {
        case '1':
          // console.log('Dashboard Overview Click')
          this.$mixpanel.track('Open Dashboard Overview', { dashboard: this.dashboardName })
          this.openSetting = 'overview'
          break
        case '2':
          this.$mixpanel.track('Open My Tasks', { dashboard: this.dashboardName })
          this.openSetting = 'mytasks'
          break
        case '3':
          this.$mixpanel.track('Open Dataroom', { dashboard: this.dashboardName })
          this.openSetting = 'mydataroom'
          break
      }
    },
    checkPermission () {
      // Check here if the user has permission to access this dashboard.
      const self = this
      const getUserByEmail = firebase
        .functions()
        .httpsCallable('getUserByEmail')
      getUserByEmail(self.getDecryptedEmail())
        .then((response) => {
          db.ref(`users/${path(['data', 'uid'], response)}/projects/${self.$route.params.projectId}/`)
            .once('value', (snapshot) => {
              if (snapshot.val()) {
                self.allowAccess = true
              } else {
                if (this.dashmoteUser) {
                  self.allowAccess = true
                } else {
                  console.log('User has no access to these settings because he is not invited to the project')
                  self.allowAccess = false
                }
              }
            })
        })
    },
    checkIsDashmoteUser () {
      const localemail = this.getDecryptedEmail()
      const emailPostfix = localemail.split('@')[1]
      if (emailPostfix === 'dashmote.com') {
        this.dashmoteUser = true
      } else {
        this.dashmoteUser = false
      }
    },
    getDecryptedEmail () {
      // Check if there is already an userEmail stored
      if (localStorage.getItem('userEmail')) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem('userEmail')), process.env.VUE_APP_AES_PASSPHRASE).toString()
        localStorage.setItem('userReference', ciphertext)
        localStorage.removeItem('userEmail')
      }
      // decrypt item
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userReference'), process.env.VUE_APP_AES_PASSPHRASE)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    }
  },
  mounted () {
    this.$mixpanel.track('Open Dashboard Management', { dashboard: this.dashboardName })
    // Check if the user has permission to access the settings
    this.checkIsDashmoteUser()
    this.checkPermission()
    // Mounted state here.
    this.getData()
    this.checkForOpenTask()
    if (this.$route.params.page === 'tasks') {
      this.defaultMenuKeys = ['2']
      this.openSetting = 'mytasks'
    }
  }
}
</script>

<style scoped>
@import './DashboardSettings.css';
</style>
