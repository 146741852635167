<template>
    <div class="detail-content">
        <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :scroll="{ y: 426 }"
        :bordered="false"
        class="table-style"
        >
            <!-- Full name -->
            <span slot="column1" slot-scope="text" v-if="text === 'Loading'" class="button-regular">
              <img class="activity-icon" style="height: 28px; width: 28px; margin-right: 16px;" :src="require('../../assets/activityicon/activityDataLoading.svg')" alt="ActivityCheck">
              <img :src="require('../../assets/loadingBar.svg')">
            </span>
            <span slot="column1" slot-scope="text" v-else-if="text" class="button-regular" style="color: #5C6075; font-weight: 500; font-size: 14px;line-height: 14px;letter-spacing: 0.005em;">
              <img class="activity-icon" style="height: 28px; width: 28px; margin-right: 16px; border-radius: 50%;" src="../../assets/icon_user.svg" alt="userIcon">
              {{ text }}
            </span>
            <span slot="column1" slot-scope="" v-else class="button-regular" style="color: #5C6075; font-weight: 500; font-size: 14px;line-height: 14px;letter-spacing: 0.005em;">
              <img class="activity-icon" style="height: 28px; width: 28px; margin-right: 16px; border-radius: 50%;" src="../../assets/icon_user.svg" alt="userIcon">
              No name picked
            </span>
            <!-- User name -->
            <span slot="column2" slot-scope="text" v-if="text === 'Loading'"><img :src="require('../../assets/loadingBarMiddle.svg')"></span>
            <span slot="column2" slot-scope="text" v-else-if="text" style="color: #5C6075;">{{ text }}</span>
            <span slot="column2" slot-scope="" v-else style="color: #5C6075;">no data</span>
            <!-- Last Seen -->
            <span slot="column3" slot-scope="text" v-if="text === 'Loading'" class="button-regular"><img :src="require('../../assets/loadingBar.svg')"></span>
            <span slot="column3" slot-scope="text" v-else-if="text" class="paragraph-regular" style="color: #5C6075;">{{ getDate(text) }}</span>
            <!-- <span slot="column3" slot-scope="" v-else class="paragraph-regular" style="color: #C4C4C4">Not seen within last month</span>-->
            <span slot="column3" slot-scope="" v-else class="paragraph-regular" style="color: #5C6075;">{{ getDate(text) }}</span>
        </a-table>
    </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'UsersTable',
  props: ['data', 'columns'],
  methods: {
    getDate (date) {
      if (!date) {
        return 'Not seen within last month'
      }
      const eventdate = moment(date)
      const todaysdate = moment()
      if (eventdate.diff(todaysdate, 'months') < 1) {
        return moment(date).fromNow()
      } else {
        return 'Not seen within last month'
      }
    }
  }
}
</script>
<style scoped>
@import './DetailPage.css';
@import '../Projects/Projects.css';
</style>
