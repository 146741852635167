<template>
<!-- For desktop view -->
    <div v-if="!mobile()" class="project-wrapper" style="margin-top: 116px !important">
        <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
        <a-row class="row-space-56">
            <a-upload
    name="avatar"
    list-type="picture-card"
    class="avatar-uploader"
    :show-upload-list="false"
    :before-upload="beforeUpload"
    @change="handleChange"
  >
            <a-col class="profile-photo" :span="4" style="width: 74px;">
                <div class="imageInn">
                    <img style="border-radius: 50%; height: 74px; width: 74px;" @error="imageError()" :src="profilePhotoURL" />
                </div>
                <div class="hoverImg">
                    <img style="height: 114px; width: 114px; margin-left:-20px; margin-top: -16px;" src="../../assets/profileimageoverlay.svg" />
                </div>
            </a-col>
    </a-upload>
            <a-col style="position:relative; margin-top: -85px; margin-left:100px;">
                <div class="header-2">My Account</div>
                <div class="paragraph-large row-space-8">Edit your personal details or reset your password.</div>
            </a-col>
        </a-row>
        <a-row :gutter="40" class="row-space-48">
            <a-col :span="12">
                <a-row class="header-4">Personal Details</a-row>
                <a-form :form="form" @submit="handleSubmit">
                <a-row class="row-space-16 table-style" style="height:460px;padding: 47px 73px;">
                    <div><a-form-item> <!-- DisplayName input -->
                        <a-row :gutter="16">
                            <a-col :span="24">
                                <div class="button-regular" style="color: #223273;">Display Name</div>
                                <div class="row-space-16">
                                    <a-input style="height: 46px;"
                                        v-decorator="['displayName', { rules: [{ required: true, message: 'Please input a valid display name' }],  initialValue:displayName }]"/>
                                </div>
                            </a-col>
                        </a-row></a-form-item>
                        <a-row :gutter="16" class="row-space-32">
                            <a-col v-if="dashmoteUser" :span="24">
                                <div class="button-regular" style="color: #223273;">Company</div>
                                <a-tooltip title="Cannot be changed for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                                <div class="row-space-16">
                                    <a-input disabled placeholder="Dashmote" style="height: 46px;"></a-input>
                                </div>
                                </a-tooltip>
                            </a-col> <!-- EDIT Company for non Dashmote user -->
                            <a-col v-else :span="24">
                                <div class="button-regular" style="color: #223273;">Company</div>
                                <div class="row-space-16">
                                    <a-form-item style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['company', { rules: [{ required: false, message: 'Please input a valid company name' }],  initialValue:company }]"/></a-form-item>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-space-32">
                            <div class="button-regular" style="color: #223273;">Email address</div>
                            <a-tooltip v-if="dashmoteUser" title="Cannot be changed for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                            <div class="row-space-16">
                                <a-input disabled :placeholder="email" style="height: 46px;"></a-input>
                            </div>
                            </a-tooltip>
                            <a-tooltip v-else title="Cannot be changed right now"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                            <div class="row-space-16">
                                <a-input disabled :placeholder="email" style="height: 46px;"></a-input>
                            </div>
                            </a-tooltip>
                        </a-row><a-form-item> <!-- Update details submit button -->
                        <a-row style="text-align: center;" class="row-space-48">
                            <a-button class="primary-button-style-total" type="primary" html-type="submit">Update Details</a-button>
                        </a-row></a-form-item>
                    </div>
                </a-row>
                </a-form>
            </a-col>
            <a-col :span="12">
               <a-row class="header-4">Password</a-row>
               <a-form :form="form" @submit="handleUpdatePassword">
                <a-row class="row-space-16 table-style" style="height:460px;padding: 47px 73px;">
                    <a-row>
                        <div class="button-regular" style="color: #223273;">Current password</div>
                        <a-tooltip v-if="dashmoteUser" title="Cannot be changed for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                                <a-input disabled placeholder="Enter your current password" style="height: 46px;"></a-input>
                        </div>
                        </a-tooltip>
                        <a-tooltip v-else title=""> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                                <a-form-item style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['currentPassword', { rules: [{required: false, message: 'Enter your current password' }] }]" type="password"/></a-form-item>
                        </div>
                        </a-tooltip>
                    </a-row>
                    <a-row class="row-space-32">
                        <div class="button-regular" style="color: #223273;">New password</div>
                        <a-tooltip v-if="dashmoteUser" title="Cannot be changed for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                            <a-input disabled placeholder="Enter your new password" style="height: 46px;"></a-input>
                        </div>
                        </a-tooltip>
                        <a-tooltip v-else title=""> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                                <a-form-item style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['newPassword', { rules: [{required: false, message: 'Enter your new password' }] }]" type="password"/></a-form-item>
                        </div>
                        </a-tooltip>
                    </a-row>
                    <a-row class="row-space-32">
                        <div class="button-regular" style="color: #223273;">Confirm password</div>
                        <a-tooltip v-if="dashmoteUser" title="Cannot be changed for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                            <a-input disabled placeholder="Confirm your new password" style="height: 46px;"></a-input>
                        </div>
                        </a-tooltip>
                        <a-tooltip v-else title=""> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <div class="row-space-16">
                                <a-form-item style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['confirmPassword', { rules: [{required: false, message: 'Confirm your new password' }] }]" type="password"/></a-form-item>
                        </div>
                        </a-tooltip>
                    </a-row><a-form-item>
                    <a-row class="row-space-48">
                        <a-tooltip title="Forgot Password is not available for Dashmote users"> <!-- ADD V-if later to check if logged in with dashmote ID -->
                        <a-col :span="12">
                            <a disabled @click="forgotPassword" class="forgot-pwd-style"></a> <!-- No option for forgotPassword now -->
                        </a-col>
                        </a-tooltip>
                        <a-col v-if="dashmoteUser" :span="12">
                            <a-button disabled class="primary-button-style-total" html-type="submit" style="float: right;">Update Password</a-button>
                        </a-col>
                        <a-col v-else :span="12">
                            <a-button class="primary-button-style-total" html-type="submit" style="float: right;">Update Password</a-button>
                        </a-col>
                    </a-row></a-form-item>
                </a-row>
               </a-form>
            </a-col>
        </a-row>
    </div>

<!-- For mobile view -->
    <div v-else class="project-wrapper">
        <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
        <a-row class="row-space-56">
            <a-col :span="4" style="width: 75px;"><img style="border-radius: 50%; height: 64px; width: 64px;" @error="imageError()" :src="profilePhotoURL" /></a-col>
            <a-col>
                <div class="header-1" style="color:#223273">My Account</div>
                <div class="paragraph-large-mobile row-space-8">Edit your personal details or reset your password.</div>
            </a-col>
        </a-row>

        <a-tabs type="card" style="margin-top: 15px;">
        <a-tab-pane key="1" tab="Profile">
          <a-row :gutter="40" class="row-space-8">
            <a-col :span="24">
              <a-form :form="form" @submit="handleSubmit">
                <a-row class="row-space-16" style="height:460px;padding: 0px 0px;">
                    <div>
                        <a-row :gutter="16">
                            <a-col :span="24">
                                <div class="button-regular" style="color: #223273;">Display Name</div>
                                <div class="row-space-16">
                                    <a-form-item style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['displayName', { rules: [{ required: true, message: 'Please input a valid display name' }],  initialValue:displayName }]"/></a-form-item>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row :gutter="16" class="row-space-32">
                            <a-col :span="24">
                                <div class="button-regular" style="color: #223273;">Company</div>
                                <div class="row-space-16">
                                  <a-input v-if="dashmoteUser" disabled placeholder="Dashmote" style="height: 46px;"></a-input>
                                  <a-form-item v-else style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['company', { rules: [{ required: false, message: 'Please input a valid company name' }],  initialValue:company }]"/></a-form-item>
                                </div>
                            </a-col>
                        </a-row>
                        <a-row class="row-space-32">
                            <div class="button-regular" style="color: #223273;">Email address</div>
                            <div class="row-space-16">
                                <a-input disabled placeholder="email" style="height: 46px;"></a-input>
                            </div>
                        </a-row>
                        <a-form-item> <!-- Update details submit button -->
                        <a-row style="text-align: center;" class="row-space-48">
                            <a-button class="primary-button-style-total" type="primary" html-type="submit">Update Details</a-button>
                        </a-row></a-form-item>
                    </div>
                </a-row>
              </a-form>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Password">
          <a-form :form="form" @submit="handleUpdatePassword">
          <a-row class="row-space-16" style="height:460px;padding: 5px 0px;">
                    <a-row>
                        <div class="button-regular" style="color: #223273;">Current password</div>
                        <div class="row-space-16">
                            <a-input v-if="dashmoteUser" disabled placeholder="Current password" style="height: 46px;"></a-input>
                            <a-form-item v-else style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['currentPassword', { rules: [{required: false, message: 'Enter your current password' }] }]" type="password"/></a-form-item>
                        </div>
                    </a-row>
                    <a-row class="row-space-32">
                        <div class="button-regular" style="color: #223273;">New password</div>
                        <div class="row-space-16">
                            <a-input v-if="dashmoteUser" disabled placeholder="New password" style="height: 46px;"></a-input>
                            <a-form-item v-else style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['newPassword', { rules: [{required: false, message: 'Enter your new password' }] }]" type="password"/></a-form-item>
                        </div>
                    </a-row>
                    <a-row class="row-space-32">
                        <div class="button-regular" style="color: #223273;">Confirm password</div>
                        <div class="row-space-16">
                            <a-input v-if="dashmoteUser" disabled placeholder="Confirm password" style="height: 46px;"></a-input>
                            <a-form-item v-else style="margin-bottom: 0px;"><a-input style="height: 46px;"
                                        v-decorator="['confirmPassword', { rules: [{required: false, message: 'Confirm your new password' }] }]" type="password"/></a-form-item>
                        </div>
                    </a-row>
                    <a-row class="row-space-48"><a-form-item>
                        <a disabled href="" class="forgot-pwd-style"></a><!-- No option for forgotPassword now -->
                        <a-button v-if="dashmoteUser" disabled class="primary-button-style-total" style="float: right;">Update Password</a-button>
                        <a-button v-else class="primary-button-style-total" html-type="submit" style="float: right;">Update Password</a-button></a-form-item>
                    </a-row>
                </a-row>
          </a-form>
        </a-tab-pane>
      </a-tabs>
    </div>
</template>

<script>
import firebase from 'firebase'
import { db } from '@/firebase'
var CryptoJS = require('crypto-js')
// import e from 'express';

const mobile = require('is-mobile')

function getBase64 (img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'Account',
  components: {

  },
  data () {
    return {
      mobile,
      overlay: false,
      forgotPasswordText: 'Forgot your password?',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      displayName: '',
      email: '',
      profilePhotoURL: require('../../assets/icon_user.svg'),
      profilePhotoPlaceholder: require('../../assets/icon_user.svg'),
      username: '',
      loading: false,
      imageUrl: '',
      dashmoteUser: false,
      company: '',
      googlePhotoURL: require('../../assets/icon_user.svg'),
      uid: ''
    }
  },
  methods: {
    handleChange (info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl => {
          this.profilePhotoURL = imageUrl
          this.loading = false
        })
      }
    },
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!')
      }
      if (isJpgOrPng && isLt2M) {
        this.uploadImage(file)
      }
      return isJpgOrPng && isLt2M
    },
    uploadImage (file) {
      const self = this
      var storageRef = firebase.storage().ref()

      // Create the file metadata
      var metadata = {
        contentType: 'image/jpeg'
      }

      // Upload file and metadata
      var uploadTask = storageRef.child('avatars/' + this.uid).put(file, metadata)

      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running')
              break
          }
        }, function (error) {
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break

            case 'storage/canceled':
              // User canceled the upload
              break

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break
          }
        }, function () {
          // Upload completed successfully, now we can get the download URL
          uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
            // console.log('File available at', downloadURL)
            self.setNewProfilePhoto(downloadURL)
            self.profilePhotoURL = downloadURL
            self.$emit('update-header', self.displayName, self.profilePhotoURL)
          })
        })
    },
    setNewProfilePhoto (profilePhotoURL) {
      const self = this
      var profilePhotoData = {
        photoURL: profilePhotoURL
      }
      db.ref(`users/${self.uid}`).update(profilePhotoData)
        .then(() => {
          console.log('success')
          return 'success'
        })
        .catch((error) => {
          console.log('ERROR', error.toString())
        })
    },
    imageError () {
      this.profilePhotoURL = this.profilePhotoPlaceholder
    },
    forgotPassword () {
      var auth = firebase.auth()
      var emailAddress = this.getDecryptedEmail()
      this.forgotPasswordText = 'Password recovery email has been send.' // Make this better later

      auth.sendPasswordResetEmail(emailAddress).then(function () {
        // Email sent.
        console.log('password recovery email send')
      }).catch(function (error) {
        // An error happened.
        console.log(error)
      })
    },
    getProfilePhotoFromDB () {
      const self = this
      db.ref('users/' + self.uid + '/').once('value', snapshot => {
        if (snapshot.val().photoURL) {
          this.profilePhotoURL = snapshot.val().photoURL
        } else {
          this.profilePhotoURL = this.googlePhotoURL
        }
      })
    },
    getDecryptedEmail () {
      // Check if there is already an userEmail stored
      if (localStorage.getItem('userEmail')) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem('userEmail')), process.env.VUE_APP_AES_PASSPHRASE).toString()
        localStorage.setItem('userReference', ciphertext)
        localStorage.removeItem('userEmail')
      }
      // decrypt item
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userReference'), process.env.VUE_APP_AES_PASSPHRASE)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    },
    getCompany () {
      const self = this
      db.ref('users/' + self.uid + '/').once('value', snapshot => {
        if (typeof snapshot.val().company === 'undefined') {
          self.company = 'Company not set'
        } else {
          self.company = snapshot.val().company
        }
      })
    },
    setCompany (companyValue) {
      const self = this
      var companyData = {
        company: companyValue
      }
      db.ref(`users/${self.uid}`).update(companyData)
        .then(() => {
          console.log('success')
          return 'success'
        })
        .catch((error) => {
          console.log('ERROR', error.toString())
        })
    },
    setDisplayNameInDB (displayNameValue) {
      const self = this
      var displayNameData = {
        displayName: displayNameValue
      }
      db.ref(`users/${self.uid}`).update(displayNameData)
        .then(() => {
          console.log('success')
          return 'success'
        })
        .catch((error) => {
          console.log('ERROR', error.toString())
        })
    },
    getUser () {
      firebase.auth().onAuthStateChanged(user => {
        if (user.displayName !== null && user.displayName !== '') {
          this.displayName = user.displayName
        } else {
          this.displayName = this.getDecryptedEmail()
        }
        this.uid = user.uid
        if (user.photoURL !== null && user.photoURL !== '') {
          this.googlePhotoURL = user.photoURL
        }
        this.getProfilePhotoFromDB()
        this.email = user.email
        this.username = user.email.split('@')[0]
        this.getCompany()
      })
    },
    setUserDetails (newDisplayName, newEmail) {
      var user = firebase.auth().currentUser

      user.updateProfile({
        displayName: newDisplayName,
        email: newEmail
      }).then(function () {
        // Update successful.
      }).catch(function (error) {
        // An error happened.
        console.log(error)
      })
    },
    updatePassword (oldPassword, newPassword) {
      const self = this
      var user = firebase.auth().currentUser
      var credential = firebase.auth.EmailAuthProvider.credential(this.getDecryptedEmail(), oldPassword)
      user.reauthenticateWithCredential(credential).then(function () {
        // User re-authenticated.
        user.updatePassword(newPassword).then(function () {
        // Update successful.
          self.$message.success('Password has been sucessfully updated')
        }).catch(function (error) {
        // An error happened.
          console.log(error)
        })
      }).catch(function (error) {
        // An error happened.
        self.$error({
          title: 'Error re-authenticating. The entered current password could be wrong.'
        })
        console.log(error)
      })
    },
    handleUpdatePassword (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.currentPassword) {
            if (values.newPassword && values.confirmPassword) {
              if (values.newPassword === values.confirmPassword) {
                if (values.newPassword.length >= 8) {
                  this.updatePassword(values.currentPassword, values.newPassword)
                  this.form.resetFields()
                } else {
                  this.$error({
                    title: 'The new password should have atleast 8 characters! Please try again'
                  })
                }
              } else {
                this.$error({
                  title: 'Passwords do not match, please try again!'
                })
              }
            } else {
              this.$error({
                title: 'New password and confirm password needs to be filled out, please try again!'
              })
            }
          } else {
            this.$error({
              title: 'Current password needs to be filled out, please try again!'
            })
          }
        }
      })
    },
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.displayName) { this.displayName = values.displayName }
          this.setUserDetails(this.displayName, this.email)
          if (!this.dashmoteUser) {
            this.setCompany(values.company)
          }
          this.setDisplayNameInDB(this.displayName)
          this.$emit('update-header', this.displayName, this.profilePhotoURL)
          this.getUser()
        }
      })
    }
  },
  created () {
    // console.log('User email in store is: ' + this.$store.getters.userEmail)
    // Get the latest userdata
    this.getUser()
    // Check if is Dashmote user
    const localemail = this.getDecryptedEmail()
    const emailPostfix = localemail.split('@')[1]
    if (emailPostfix === 'dashmote.com') {
      this.dashmoteUser = true
    } else {
      this.dashmoteUser = false
    }
  }
}

</script>

<style scoped>
@import '../Projects/Projects.css';
@import '../Dashboards/DetailPage.css';
@import './Account.css';
</style>
