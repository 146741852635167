import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router/index.js'
import Antd from 'ant-design-vue'
import './assets/css/dashmote.css'
import './firebase'
import store from './store/'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import VueIntercom from '@mathieustan/vue-intercom'
import VueProgressBar from 'vue-progressbar'
import Vuetify, {
  VOverlay
} from 'vuetify/lib'

// Using the vue-flexmonster module as a plugin (global registration):
// 1. Importing the vue-flexmonster module and CSS styles
import Pivot from 'vue-flexmonster'
import 'flexmonster/flexmonster.css'
import { PieChart } from '@opd/g2plot-vue'

// main.js
import VMdPreview from '@kangc/v-md-editor/lib/preview'
import '@kangc/v-md-editor/lib/style/preview.css'
// Introduce the theme you use. Take the github theme as an example here
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
// import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import './pages/Dashboards/Settings/DashMarkdownStyle.css'

// highlightjs
import hljs from 'highlight.js'

import createEmojiPlugin from '@kangc/v-md-editor/lib/plugins/emoji/index'
import '@kangc/v-md-editor/lib/plugins/emoji/emoji.css'

Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM })
var mixpanel = require('mixpanel-browser')
mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN)

// 2. Referring to the vue-flexmonster module as a plugin
Vue.use(Pivot)

Vue.use(PieChart)

Vue.config.productionTip = false
Vue.prototype.$mixpanel = mixpanel
Vue.use(VueSidebarMenu)
Vue.use(Antd)
Vue.use(Vuex)
Vue.use(Vuetify, {
  components: {
    VOverlay
  }
})

VMdPreview.use(vuepressTheme, {
  Hljs: hljs
})
VMdPreview.use(createEmojiPlugin())
Vue.use(VMdPreview)

Vue.use(VueProgressBar, {
  position: 'absolute',
  color: 'rgb(24, 144, 255)',
  failedColor: 'red',
  height: '2px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  }
})

router.beforeEach((to, from, next) => {
  /* judge the route if it need login permission */
  if (to.matched.some(record => record.meta.requireAuth)) {
    // is login ?
    // debugger
    if (localStorage.getItem('accessToken')) {
      next()
    } else {
      // not login
      next({
        path: '/login'
      })
    }
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
