<template>
  <div style="width:100%; margin-top:62px">
    <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
    <a-row class="row" style="height:90vh;" >
      <vue-progress-bar></vue-progress-bar>
      <iframe v-if="!failed && allowAccess" :src="url" id="container" style="display: flex; width: 100%; height: 100%; margin: 0px auto; max-width:1350px;"></iframe>
      <div v-else-if="failed" class="failedLoadingPage"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">Loading has failed. Try again here:</div><br><a-button style="margin-left:25%; margin-right:25%" onClick="window.location.reload();">Refresh Page</a-button></div></div>
      <div v-else-if="!allowAccess" class="failedLoadingPage"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">You are not allowed to access this dashboard.</div><br></div></div>
    </a-row>
  </div>
</template>

<script>
/* eslint-disable */
import { db } from '@/firebase'
import firebase from 'firebase'
import { path } from 'ramda'
var CryptoJS = require('crypto-js')

export default {
  name: 'ColumboPage',
  data () {
    return {
      hideIntercom: window.innerWidth <= 1350,
      overlay: false,
      loaded: false,
      failed: false,
      allowAccess: true,
      url: '',
      dashmoteUser: false
    }
  },
  methods: {
    getData () {
      const self = this
      db.ref(`projects/${self.$route.params.projectId}/dashboards/${self.$route.params.dashboardId}`).once('value', snapshot => {
        self.url = snapshot.val().url
      })
    },
    setFailed () {
      if (!this.loaded && this.allowAccess) {
        this.failed = true
        this.loaded = true
        this.$Progress.finish()
      }
    },
    onReady (callback) {
      var intervalId = window.setInterval(function () {
        if (document.getElementById('ant-card-body') !== undefined) {
          window.clearInterval(intervalId)
          callback.call(this)
        }
      }, 11000)
    },
    getDecryptedEmail () {
      // Check if there is already an userEmail stored
      if (localStorage.getItem('userEmail')) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem('userEmail')), process.env.VUE_APP_AES_PASSPHRASE).toString()
        localStorage.setItem('userReference', ciphertext)
        localStorage.removeItem('userEmail')
      }
      // decrypt item
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userReference'), process.env.VUE_APP_AES_PASSPHRASE)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    },
    sendMessage () {
      const ifr = document.getElementById('container')
      ifr.contentWindow.postMessage(JSON.stringify({
        email: this.getDecryptedEmail(),
        projectId: this.$route.params.projectId,
        dashboardId: this.$route.params.dashboardId
      }), '*')
    },
  checkPermission () {
      // Check here if the user has permission to access this dashboard.
      const self = this
      const getUserByEmail = firebase
        .functions()
        .httpsCallable('getUserByEmail')
      getUserByEmail(self.getDecryptedEmail())
        .then((response) => {
          db.ref(`users/${path(['data', 'uid'], response)}/projects/${self.$route.params.projectId}/`)
            .once('value', (snapshot) => {
              if (snapshot.val()) {
                self.allowAccess = true
              } else {
                if (this.dashmoteUser) {
                  self.allowAccess = true
                } else {
                  self.allowAccess = false
                }
              }
            })
        })
    },
    checkIsDashmoteUser () {
      const localemail = this.getDecryptedEmail()
      const emailPostfix = localemail.split('@')[1]
      if (emailPostfix === 'dashmote.com') {
        this.dashmoteUser = true
      } else {
        this.dashmoteUser = false
      }
    }
  },
  mounted () {
    this.checkIsDashmoteUser()
    this.checkPermission()
    this.$Progress.start()
    this.getData()
    var self = this
    this.onReady(function () {
      self.loaded = true
      self.$Progress.finish()
      self.sendMessage()
    })
    setTimeout(this.setFailed, 40000) // 40 seconds timout should be very safe.
  },
  created () {
    if (innerWidth <= 1350) {
      window.Intercom('update', {
        hide_default_launcher: true
      })
    }

    addEventListener('loadingMessage', () => {
      // For future loading snapping
    })

    addEventListener('resize', () => {
      var iframe = document.getElementById("container");
      var elmnt = iframe.contentWindow.document.querySelector("#app > div.__cov-progress");
      elmnt.style.display = "none";
      // document.getElementsByClassName("__cov-progress").style.height = "50px";
      // document.querySelector("#app > div.__cov-progress").style.height = "50px";
      this.hideIntercom = innerWidth <= 1350
      if (this.hideIntercom) {
        window.Intercom('update', {
          hide_default_launcher: true
        })
      } else {
        window.Intercom('update', {
          hide_default_launcher: false
        })
      }
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../Projects/Projects.css';
@import './DetailPage.css';
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
