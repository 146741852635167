<template>
    <div class="detail-content">
        <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :bordered="false"
        class="table-style"
        >
            <!-- Full name -->
            <span slot="column1" slot-scope="text" v-if="text === 'Loading'" class="button-regular">
              <img class="activity-icon" style="height: 28px; width: 28px; margin-right: 16px;" :src="require('../../assets/activityicon/activityDataLoading.svg')" alt="ActivityCheck">
              <img :src="require('../../assets/loadingBar.svg')">
            </span>
            <span slot="column1" slot-scope="text" v-else-if="text" class="button-regular" style="color: #5C6075; font-weight: 500; font-size: 14px;line-height: 14px;letter-spacing: 0.005em;">
              <img class="activity-icon" style="height: 28px; width: 28px; margin-right: 16px; border-radius: 50%;" src="../../assets/icon_user.svg" alt="userIcon">
              {{ text }}
            </span>
            <span slot="column1" slot-scope="" v-else class="button-regular" style="color: #5C6075; font-weight: 500; font-size: 14px;line-height: 14px;letter-spacing: 0.005em;">
              <img class="activity-icon" style="height: 28px; width: 28px; margin-right: 16px; border-radius: 50%;" src="../../assets/icon_user.svg" alt="userIcon">
              NO DATA
            </span>
        </a-table>
    </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'UsersTableMobile',
  props: ['data', 'columns'],
  methods: {
    getDate (date) {
      return moment(date).format('MM-DD-YYYY')
    }
  }
}
</script>
<style scoped>
@import './DetailPage.css';
@import '../Projects/Projects.css';
</style>
