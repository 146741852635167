<template>
    <a-card class="project-dashboard-card"
            v-on:click.middle="goDashboardNewTab(items)"
            :bodyStyle="{ padding: '0px' }"
            hoverable
            @click="goDashboard(items)"
    >
        <div style="display: flex;">
            <div style="width: 70px; position: relative; height: 70px;">
                    <!-- Loading state first -->
                    <div v-if="items.name=='Loading1' || items.name=='Loading2' || items.name=='Loading3' || items.name=='Loading4'|| items.name=='Loading5'|| items.name=='Loading6'" ><img class="dashboard-icon" :src="require('../../assets/basic-loading-icon.svg')" alt="Columbo"></div>
                    <!-- Set the correct category icon -->
                    <div v-else-if="items.productLineValue==='columbo'"><a-tooltip title="Columbo"><img class="dashboard-icon" :src="require('../../assets/categoryicon/C.svg')" alt="Columbo"></a-tooltip></div>
                    <div v-else-if="items.productLineValue==='Forge'"><a-tooltip title="The Forge"><img class="dashboard-icon" :src="require('../../assets/categoryicon/F.svg')" alt="Forge"></a-tooltip></div>
                    <div v-else-if="items.productLineValue==='Project'"><a-tooltip title="Dashboard"><img class="dashboard-icon" :src="require('../../assets/categoryicon/D.svg')" alt="Dashboard"></a-tooltip></div>
                    <div v-else-if="items.productLineValue==='Analytics'"><a-tooltip title="Analytics"><img class="dashboard-icon" :src="require('../../assets/categoryicon/A.svg')" alt="Analytics"></a-tooltip></div>
                    <!-- Should not need an else, but by not doing so will break it with legacy database data -->
                    <div v-else><a-tooltip title="Dashboard"><img :src="require('../../assets/categoryicon/D.svg')" class="dashboard-icon" alt="Dashboard"></a-tooltip></div>
            </div>
            <div style="width: 100%;">
                <a-col v-if="items.name=='Loading1' || items.name=='Loading2' || items.name=='Loading3' || items.name=='Loading4'|| items.name=='Loading5'|| items.name=='Loading6'" :span="8" style="line-height: 70px; left: 10px;" class="header-3"><img :src="require('../../assets/loadingBar.svg')"></a-col>
                <a-col v-else :span="8" style="line-height: 70px; left: 10px; color: #5C6075;" class="header-3">{{ projectName }}</a-col>
               <!-- <a-col :span="4" v-if="getValue('activeDate')">{{ items.activeDate }}</a-col><a-col :span="4" v-else>no</a-col> | Currently we don't have this data in the DB -->
                <!-- Loading state first -->
                <a-col :span="3" v-if="items.name=='Loading1' || items.name=='Loading2' || items.name=='Loading3' || items.name=='Loading4'|| items.name=='Loading5'|| items.name=='Loading6'">
                    <a-tag style="color:#D3D6E3; background:#D3D6E3; border: 1px solid #D3D6E3;">loading</a-tag>
                </a-col>
                <div v-else>
                  <!-- Set the correct status tag -->
                  <a-col :span="3" v-if="getValue('productProcessValue')">
                    <a-tag style="color:#DD1155; background:#F3A9C2; border: 1px solid #DD1155;" v-if="items.productProcessValue=='Trial'">{{ items.productProcessValue }}</a-tag>
                    <a-tag style="color:#00CC74; background:#CCF5E3; border: 1px solid #00CC74;" v-else-if="items.productProcessValue=='Subscription'">{{ items.productProcessValue }}</a-tag>
                    <a-tag style="color:#C4C4C4; background:#F1F1F1; border: 1px solid #C4C4C4;" v-else>{{ items.productProcessValue }}</a-tag>
                  </a-col>
                  <a-col :span="3" v-else></a-col>
                </div>
                <!-- Loading state first -->
                <a-col :span="4" v-if="items.name=='Loading1' || items.name=='Loading2' || items.name=='Loading3' || items.name=='Loading4'|| items.name=='Loading5'|| items.name=='Loading6'"><img :src="require('../../assets/loadingBarSmall.svg')"></a-col>
                <div v-else>
                  <!-- Set the correct remaining date -->
                  <a-col :span="4" style="color: #5C6075;" :class="{redDate: getRemainDate(items.counterValue)[1] === 'red'}" v-if="getValue('counterValue')">{{ getRemainDate(items.counterValue)[0] }}</a-col>
                  <a-col :span="4" v-else></a-col>
                </div>
                <!-- Loading state first -->
                <a-col :span="5" v-if="items.name=='Loading1' || items.name=='Loading2' || items.name=='Loading3' || items.name=='Loading4'|| items.name=='Loading5'|| items.name=='Loading6'"><img :src="require('../../assets/loadingBarMiddle.svg')"></a-col>
                <div v-else>
                  <!-- Set the correct created date tag -->
                  <a-col :span="5" style="color: #5C6075;" v-if="getValue('createdAt')">{{ getDate(items.createdAt) }}</a-col>
                  <a-col :span="5" v-else></a-col>
                </div>
                <a-col :span="4" v-if="items.name=='Loading1' || items.name=='Loading2' || items.name=='Loading3' || items.name=='Loading4'|| items.name=='Loading5'|| items.name=='Loading6'"><img :src="require('../../assets/loadingBarSmall.svg')"></a-col>
                <div v-else>
                  <!-- Set the correct go to settings button -->
                  <a-col :span="4" v-if="items.settings"><span @click="goDashboardSettings()"><img style="margin-right: 8px" src="../../assets/setupstepicon/openManagement.svg"><span class="management-link">Management</span></span></a-col>
                  <!--<a-col :span="4" v-if="!items.settings"><a disabled @click="goDashboardSettings()">Open Settings</a></a-col>-->
                  <a-col :span="4" v-else></a-col>
                </div>
            </div>
        </div>
    </a-card>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
const R = require('ramda')
var CryptoJS = require('crypto-js')
export default {
  name: 'DashboardCard',
  props: ['projectName', 'items'],
  data () {
    return {
      toSettingsClick: false
    }
  },
  methods: {
    getDashboardUrl () {
      if (R.has('url')(this.items)) {
        if (this.items.url.startsWith('https://columbo') || this.items.url.startsWith('https://colombo')) {
          return `/project/${this.$route.params.id}/columbo/${this.items.id}`
        } else if (this.items.url.startsWith('https://app.powerbi.com')) {
          return `/project/${this.$route.params.id}/forge/${this.items.id}`
        }
      } else {
        return 'No valid dashboard url'
      }
    },
    getDecryptedEmail () {
      // Check if there is already an userEmail stored
      if (localStorage.getItem('userEmail')) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem('userEmail')), process.env.VUE_APP_AES_PASSPHRASE).toString()
        localStorage.setItem('userReference', ciphertext)
        localStorage.removeItem('userEmail')
      }
      // decrypt item
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userReference'), process.env.VUE_APP_AES_PASSPHRASE)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    },
    goDashboardSettings () {
      this.toSettingsClick = true
      this.$router.push(`/project/${this.$route.params.id}/dashboard/${this.items.id}/settings`)
    },
    goDashboard (items) {
      const self = this

      if (items.productLineValue === 'Analytics') {
        this.$router.push(`/analytics/${this.items.id}`)
        // If not analystics page go to normal Dashboard
      } else if (!this.toSettingsClick) {
        if (this.getValue('counterValue') && (this.formatDate(this.items.counterValue) === 'expired')) {
          this.$message.error('Your dashboard license seems to be expired, please contact your CSM')
          this.$intercom.show()
          axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
            text: `User clicked on expired dashboard
      User: ${self.getDecryptedEmail()}
      Dashboard name: ${this.projectName}
      Dashboard url: https://app.dashmote.com${this.getDashboardUrl()}`
          }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
          return false
        }
        if (R.has('url')(this.items)) {
          this.$mixpanel.time_event('Session End')
          // Track opening a specific dashboard for Mixpanel.
          this.$mixpanel.track('Open Dashboard', { app: 'columbo', 'Dashboard name': this.projectName })
          if (this.items.url.startsWith('https://columbo') || this.items.url.startsWith('https://colombo')) {
            this.$router.push(`/project/${this.$route.params.id}/columbo/${this.items.id}`)
          } else if (this.items.url.startsWith('https://app.powerbi.com')) {
            this.$router.push(`/project/${this.$route.params.id}/forge/${this.items.id}`)
          }
        } else {
          if (items.settings) {
            this.$router.push(`/project/${this.$route.params.id}/dashboard/${this.items.id}/settings`)
          } else {
            self.$error({
              title: 'No valid dashboard url'
            })
          }
        }
      }
    },
    goDashboardNewTab (items) {
      // Addition to trigger the Clubhouse tracking event tracking.
      const self = this
      if (!this.toSettingsClick) {
        if (this.getValue('counterValue') && (this.formatDate(this.items.counterValue) === 'expired')) {
          this.$message.error('Your dashboard license seems to be expired, please contact your CSM')
          this.$intercom.show()
          axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
            text: `User clicked on expired dashboard
      User: ${self.getDecryptedEmail()}
      Dashboard name: ${this.projectName}
      Dashboard url: https://dashmote-client-app-staging.herokuapp.com${this.getDashboardUrl()}`
          }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
          return false
        }
        if (R.has('url')(this.items)) {
          this.$mixpanel.time_event('Session End')
          // Track opening a specific dashboard for Mixpanel.
          this.$mixpanel.track('Open Dashboard', { app: 'columbo', 'Dashboard name': this.projectName })
          if (this.items.url.startsWith('https://columbo') || this.items.url.startsWith('https://colombo')) {
            const routeData = this.$router.resolve({ path: `/project/${this.$route.params.id}/columbo/${this.items.id}` })
            window.open(routeData.href, '_blank')
          } else if (this.items.url.startsWith('https://app.powerbi.com')) {
            const routeData = this.$router.resolve({ path: `/project/${this.$route.params.id}/forge/${this.items.id}` })
            window.open(routeData.href, '_blank')
          }
        } else {
          if (items.settings) {
            const routeData = this.$router.resolve({ path: `/project/${this.$route.params.id}/dashboard/${this.items.id}/settings` })
            window.open(routeData.href, '_blank')
          } else {
            self.$error({
              title: 'No valid dashboard url'
            })
          }
        }
      }
    },
    getValue (target) {
      return R.has(target)(this.items)
    },
    getRemainDate (text) {
      if (moment(text).diff(moment().format('MM/DD/YYYY'), 'days') < 7) { // Check if shorter than 7 days
        return [this.formatDate(text), 'red'] // and make red
      } else {
        return [this.formatDate(text), 'black']
      }
    },
    formatDate (text) {
      const eventdate = moment(text)
      const todaysdate = moment().format('MM/DD/YYYY')
      if (eventdate.diff(todaysdate, 'years') > 1) {
        return eventdate.diff(todaysdate, 'years') + ' years left'
      } else if (eventdate.diff(todaysdate, 'months') > 1) {
        return eventdate.diff(todaysdate, 'months') + ' months left'
      } else if (eventdate.diff(todaysdate, 'days') > 0) {
        return eventdate.diff(todaysdate, 'days') + ' days left'
      } else {
        return 'expired'
      }
      // return date
    },

    getDate (date) {
      return 'created on ' + moment(date).format('DD MMM YYYY')
    }
  }
}
</script>
<style scoped>
@import './DetailPage.css';
@import '../Projects/Projects.css';
</style>
