<template>
<!-- For desktop view -->
    <div v-if="!mobile()" style="width:100%; height: calc(100% - 6px);">
        <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
        <iframe allowfullscreen v-if="this.$route.params.pathMatch" :src="this.parsePath()" style="height: 100%; width:100%;"></iframe>
        <iframe allowfullscreen v-else src="https://du.getdashmote.com/" style="height: 100%; width:100%;"></iframe>
    </div>
    <div v-else style="width:100%; height: 100%;">
        <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
        <iframe allowfullscreen v-if="this.$route.params.pathMatch" :src="this.parsePath()" style="height: 100%; width:100%;"></iframe>
        <iframe allowfullscreen v-else src="https://du.getdashmote.com/" style="height: 100%; width:100%;"></iframe>
       </div>
</template>

<script>
const mobile = require('is-mobile')

export default {
  name: 'University',
  components: {

  },
  data () {
    return {
      mobile,
      overlay: false
    }
  },
  methods: {
    parsePath () {
      var path = 'https://du.getdashmote.com/'
      if (this.$route.params.pathMatch.startsWith('course/')) {
        path = 'https://du.getdashmote.com/course/view.php?id=' + this.$route.params.pathMatch.split('course/')[1]
      } else if (this.$route.params.pathMatch.startsWith('resource/')) {
        path = 'https://du.getdashmote.com/mod/resource/view.php?id=' + this.$route.params.pathMatch.split('resource/')[1]
      } else if (this.$route.params.pathMatch.startsWith('category/')) {
        path = 'https://du.getdashmote.com/course/index.php?categoryid=' + this.$route.params.pathMatch.split('category/')[1]
      }
      return path
    }
  },
  // Hides the header on the top and hide Intercom
  created () {
    this.$emit('hide-header')
    this.$mixpanel.track('Open Dashmote University')
  },
  // Shows the header on the top and shows Intercom again
  beforeRouteLeave (to, from, next) {
    this.$emit('show-header')
    next()
    this.$mixpanel.track('Change Dashmote University URL')
  }
}

</script>

<style scoped>

</style>
