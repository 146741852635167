<template>
    <a-card class="project-big-card" :style="`background: ${backgroundcolor};`" @click="goToUrl(linkurl)">
    <a-row :span="24">
      <div class="projectSlideCard" :style="`float: left;`">
        <span class="icon-text" :style="`color: ${backgroundcolor}; background-color: ${iconbackgroundcolor};`">{{category}}</span>
      </div>
      <div><img style="float: right; margin-top: 6px;" src="../../assets/arrow_right_white.svg"></div>
    </a-row>
    <a-row :span="24" style="margin-top: 8px;" class="project-card-slide-font">{{ cardContent }}</a-row>
    <img v-if="cardKey === '1'" class="intersect1" src="../../assets/blob1.svg" alt="">
    <img v-if="cardKey === '2'" class="intersect2" src="../../assets/blob2.svg" alt="">
    <img v-if="cardKey === '3'" class="intersect3" src="../../assets/blob3.svg" alt="">
  </a-card>
</template>
<script>
export default {
  name: 'ProjectSlideCard',
  props: ['category', 'cardContent', 'linkurl', 'backgroundcolor', 'iconbackgroundcolor', 'cardKey'],
  methods: {
    getIcon (name) {
      return require(`@/assets/${name}.svg`)
    },
    goToUrl (linkurl) {
      if (linkurl === 'DashmoteUniversityRedirect') {
        this.$router.push('/university')
      } else {
        window.open(
          linkurl,
          '_blank' // <- This is what makes it open in a new window.
        )
      }
    }
  }
}
</script>
<style scoped>
@import './Projects.css';
@import './ProjectSlideCard.css';
</style>
