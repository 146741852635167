<template>
    <div class="dashboard-wrapper" v-if="!mobile()">
        <a-row class="dashboard-subtitle-row detail-content">
            <img :src="require('../../assets/icon_grid_filled.svg')" style="vertical-align: -8px; margin-left: 26px; margin-right: 16px; margin-top: 21px;" alt="">
            <span style="color: #373B53;" class="header-4">{{ name }} <span v-if="dashmoteUser"></span><span class="admin-link" v-if="dashmoteUser" @click="openAdminApp"> Admin</span><img :src="openDashboardIcon" style="height: 12px;vertical-align: -2px; margin-left: 4px;" alt=""></span>
            <a-input-search placeholder="Search for a keyword" style="width: 200px; float: right;line-height: 66px;margin-right: 20px;" @change="onSearch" />
        </a-row>
        <a-row v-if="!filterCardData" class="row-space-8 noDashboardsPage">
            <img style="display: block; margin-left: auto; margin-right: auto; width: 257px; margin-top:54px" :src="require('../../assets/noDashboardsImage.svg')">
            <div class="noProjectsTitle" >No dashboards available yet.</div>
            <div class="noProjectsText" >Dashmote will add them to your workspace soon.</div>
        </a-row>
        <div v-else-if="!allowAccess" class="failedLoadingPage" style="margin-top:5%"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">You are not allowed to access this project.</div><br></div></div>
        <a-row v-else class="row-space-8" style="height: 480px; overflow-y: auto;">
            <DashboardCard
                v-for="item in filterCardData"
                :key="item['name']"
                :projectName="item.name"
                :items="item"
            />
        </a-row>
    </div>

    <div class="dashboard-wrapper" v-else>
        <a-row class="dashboard-subtitle-row detail-content">
            <img :src="require('../../assets/icon_grid_filled.svg')" style="vertical-align: -8px; margin-left: 26px; margin-right: 16px; margin-top: 21px;" alt="">
            <span style="color: #373B53;" class="header-4">{{ name }}</span>
            <a-input-search placeholder="Search for a keyword" style="width: 250px; float: right;line-height: 66px; margin-left: 20px; margin-right: 20px;" @change="onSearch" />
        </a-row>
        <a-row v-if="!filterCardData" class="row-space-8 noDashboardsPage">
            <img style="display: block; margin-left: auto; margin-right: auto; width: 257px; margin-top:54px" :src="require('../../assets/noDashboardsImage.svg')">
            <div class="noProjectsTitle" >No dashboards yet.</div>
            <div class="noProjectsText" >Dashmote will add them to your projects soon.</div>
        </a-row>
        <div v-else-if="!allowAccess" class="failedLoadingPage" style="margin-top:5%"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">You are not allowed to access this project.</div><br></div></div>
        <a-row v-else class="row-space-8 mobile-dashboard-card-area" style="height: 380px; overflow-y: auto; ">
            <DashboardCardMobile
                v-for="item in filterCardData"
                :key="item['name']"
                :projectName="item.name"
                :items="item"
            />
        </a-row>
    </div>
</template>
<script>
import DashboardCard from './DashboardCard.vue'
import DashboardCardMobile from './DashboardCardMobile.vue'
import moment from 'moment'
import firebase from 'firebase'
import axios from 'axios'
import { path } from 'ramda'
import { db } from '../../firebase'
const R = require('ramda')
const mobile = require('is-mobile')
var CryptoJS = require('crypto-js')

export default {
  name: 'Dashboard',
  components: {
    DashboardCard,
    DashboardCardMobile
  },
  data () {
    return {
      mobile,
      cardData: {
        loadingDashboardKey1: { name: 'Loading1' },
        loadingDashboardKey2: { name: 'Loading2' },
        loadingDashboardKey3: { name: 'Loading3' },
        loadingDashboardKey4: { name: 'Loading4' },
        loadingDashboardKey5: { name: 'Loading5' },
        loadingDashboardKey6: { name: 'Loading6' }
      },
      search: '',
      name: '',
      allowAccess: true,
      dashmoteUser: false,
      openDashboardIcon: require('../../assets/arrow_diagonal_up_right_blue.svg')
    }
  },
  methods: {
    onSearch (e) {
      this.search = e.target.value
    },
    getData () {
      const self = this
      db.ref('projects/' + self.$route.params.id).once('value', snapshot => {
        if (R.has('name')(snapshot.val())) {
          self.name = snapshot.val().name
        }
        if (R.has('dashboards')(snapshot.val())) {
          const results = []
          const keys = R.keys(snapshot.val().dashboards)
          const putKeyintoVal = (x) => {
            const res = snapshot.val().dashboards[x]
            res.id = x
            if (res.draft === false) {
              results.push(res)
            }
          }
          R.forEach(putKeyintoVal, keys)
          self.cardData = results
        } else {
          self.cardData = { emptyFolder: true }
        }
      })
    },
    isExpired (date) {
      if (!date) {
        return false
      }
      const remainDate = moment(date, 'MM/DD/YYYY').fromNow()
      if (remainDate.indexOf('ago') !== -1) {
        return false
      } else {
        return true
      }
    },
    isContain (item) {
      if (this.search && this.search !== undefined) {
        let isRight
        try {
          isRight = item.name.toLowerCase().includes(this.search.toLowerCase())
        } catch (err) {
          console.log(err)
        }
        return isRight
      } else {
        return true
      }
    },
    getDecryptedEmail () {
      // Check if there is already an userEmail stored
      if (localStorage.getItem('userEmail')) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem('userEmail')), process.env.VUE_APP_AES_PASSPHRASE).toString()
        localStorage.setItem('userReference', ciphertext)
        localStorage.removeItem('userEmail')
      }
      // decrypt item
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userReference'), process.env.VUE_APP_AES_PASSPHRASE)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    },
    checkPermission () {
      // Check here if the user has permission to access this dashboard.
      const self = this
      const getUserByEmail = firebase
        .functions()
        .httpsCallable('getUserByEmail')
      getUserByEmail(self.getDecryptedEmail())
        .then((response) => {
          db.ref(`users/${path(['data', 'uid'], response)}/projects/${self.$route.params.id}/`)
            .once('value', (snapshot) => {
              if (snapshot.val()) {
                self.allowAccess = true
              } else {
                if (this.dashmoteUser) {
                  self.allowAccess = true
                } else {
                  self.allowAccess = false
                }
              }
            })
        })
    },
    openAdminApp () {
      const link = 'https://admin.getdashmote.com/dashboard/detail/' + this.$route.params.id
      window.open(link)
    },
    checkIsDashmoteUser () {
      const localemail = this.getDecryptedEmail()
      const emailPostfix = localemail.split('@')[1]
      if (emailPostfix === 'dashmote.com') {
        this.dashmoteUser = true
      } else {
        this.dashmoteUser = false
      }
    }
  },
  computed: {
    filterCardData () {
      const self = this
      if (Array.isArray(this.cardData) && !this.cardData.length) {
        axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
          text: `User opened folder with no published dashboards
      User: ${self.getDecryptedEmail()}
      Project:  http://admin.getdashmote.com/dashboard/detail/${self.$route.params.id} (${self.name})`
        }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        return ''
      } else if (this.cardData.emptyFolder) {
        axios.post(process.env.VUE_APP_SLACK_WEBHOOK, {
          text: `User opened folder with no dashboards
      User: ${self.getDecryptedEmail()}
      Project:  http://admin.getdashmote.com/dashboard/detail/${self.$route.params.id} (${self.name})`
        }, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        return ''
      } else {
        return R.filter(this.isContain)(this.cardData)
      }
    }
  },
  created () {
    this.checkIsDashmoteUser()
    this.checkPermission()
    this.getData()
  }
}
</script>
<style scoped>
@import './DetailPage.css';
@import '../Projects/Projects.css';
</style>
