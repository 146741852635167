<template>
    <a-card class="project-card"
            :bodyStyle="{ padding: '0px' }"
            hoverable
            @click="goToProject()"
            v-if="!mobile()"
    >
        <div style="display: flex;">
            <div style="width: 70px; position: relative; height: 70px;">
                <div class="project-card-icon">
                    <img v-if="value.name === 'Loading'" height="" :src="require('../../assets/basic-loading-icon.svg')" alt="">
                    <img v-else height="" :src="require('../../assets/project_outline.svg')" alt="">
                    <!-- Can be used later when using dynamic icons  -->
                    <!--<img height="20" :src="getIcon(icon)" alt="">-->
                </div>
            </div>
            <div style="width: 100%;">
                <a-col v-if="value.name === 'Loading'" :span="10" style="line-height: 70px;" class="header-3"><img :src="require('../../assets/loadingBar.svg')"></a-col>
                <a-col v-else :span="10" style="line-height: 70px;" class="header-3">{{ value.name }}</a-col>
                <a-col v-if="value.name === 'Loading'" :span="7"><img :src="require('../../assets/loadingBarSmall.svg')"></a-col>
                <a-col v-else :span="4"><span class="header-3">{{ getCount(value.dashboards) }} dashboards</span></a-col>
                <a-col v-if="value.name === 'Loading'" :span="7"><img :src="require('../../assets/loadingBarMiddle.svg')"></a-col>
                <a-col v-else :span="8" style="color: #373B53">created on {{ getDate() }}</a-col>
                <a-col v-if="value.name === 'Loading'" :span="7"><img :src="require('../../assets/loadingBarSmall.svg')"></a-col>
                <a-col v-else :span="2"><span class="open-text">Open</span><img style="margin-left:8px;" :src="require('../../assets/arrow_right.svg')"></a-col>
            </div>
        </div>
    </a-card>
    <a-card class="project-card"
            :bodyStyle="{ padding: '0px' }"
            hoverable
            @click="goToProject"
            v-else
    >
        <div style="display: flex;">
            <div style="width: 70px; position: relative; height: 70px;">
                <div class="project-card-icon">
                    <img v-if="value.name === 'Loading'" :src="require('../../assets/basic-loading-icon.svg')" alt="">
                    <img v-else :src="require('../../assets/basic-icon.svg')" alt="">
                </div>
            </div>
            <div style="width: 100%;">
                <a-col v-if="value.name === 'Loading'" :span="14" style="line-height: 70px;" class="header-3"><img :src="require('../../assets/loadingBar.svg')"></a-col>
                <a-col v-else :span="14" style="line-height: 70px;" class="header-3">{{ value.name }}</a-col>
            </div>
        </div>
    </a-card>
</template>
<script>

import { values } from 'ramda'
import * as moment from 'moment'
const mobile = require('is-mobile')
export default {
  name: 'ShowProjectCard',
  props: ['projectId', 'value', 'icon'],
  data () {
    return {
      mobile
    }
  },
  methods: {
    getIcon (name) {
      return require(`@/assets/${name}.svg`)
    },
    getCount (value) {
      return values(value).length
    },
    getDate () {
      if (Object.keys(this.value).indexOf('createdAt') !== -1) {
        return moment(this.value.createdAt).format('YYYY-MM-DD')
      } else {
        return 'NO TIME'
      }
    },
    goToProject () {
      if (this.value.name !== 'Loading') {
        this.$router.push('/project/' + this.projectId)
        // Track when a user opens a project for Mixpanel.
        this.$mixpanel.track('Open Project', { 'Project name': this.value.name })
      }
    }
  },
  created () {
    // debugger
  }
}
</script>
<style scoped>
@import './Projects.css';
</style>
