<template>
      <a-layout-sider style="position: absolute; height: 100%; z-index:1000; background-color: #373B53;" v-model="collapsed" :trigger="null" collapsible collapsedWidth="0px" width="264px">
      <div class="sidebar-block">
      <div v-if="!collapsed" v-click-outside="hide"/>

      <div class="logo"><img src="../../../assets/dashmote-logo-white.svg" alt=""></div>
      <a-menu theme="dark"  style="background-color: #373B53; margin-bottom:89px" mode="inline" :selectedKeys="selectedKey" :default-selected-keys="defaultKey" @click="handleClick">
        <a-menu-item key="1" >
          <div class="menu-item-content">
            <img height="" src="../../../assets/menuicons/projects-icon.svg" alt="">
            <span style="margin-left: 24px ">Projects</span>
          </div>
        </a-menu-item>
        <!-- Dashmote University -->
        <a-menu-item key="2">
          <div class="menu-item-content">
          <img height="" :src="require('../../../assets/menuicons/university-icon.svg')" alt="">
          <span style="margin-left: 24px ">Dashmote University</span>
          </div>
        </a-menu-item>
        <!-- -->
      </a-menu>
      <span style="color: white; margin-left:15px;">SETTINGS</span>
      <a-menu theme="dark" style="background-color: #373B53;" mode="inline" :selectedKeys="selectedKey" :default-selected-keys="['']" @click="handleClick">
        <a-menu-item key="3" >
          <div class="menu-item-content">
          <img height="" :src="require('../../../assets/menuicons/user-icon.svg')" alt="">
          <span style="margin-left: 24px ">Settings</span>
          </div>
        </a-menu-item>
        <a-menu-item key="4">
          <div class="menu-item-content">
          <img height="" :src="require('../../../assets/menuicons/log-out-icon.svg')" alt="">
          <span style="margin-left: 24px ">Log Out</span>
          </div>
        </a-menu-item>
      </a-menu>
      </div>
    </a-layout-sider>

</template>

<script>
import firebase from 'firebase'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'Sidebar',
  components: {
  },
  data () {
    return {
      defaultKey: ['1'],
      selectedKey: ['1'],
      collapsed: true
    }
  },
  mounted () {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el
  },
  directives: {
    ClickOutside
  },
  methods: {
  // This method will be called from component Header to toggle the sider.
    handleClick (e) {
      switch (e.key) {
        case '1':
          this.$emit('toggle-side-menu-from-sidebar')
          this.selectedKey = ['1']
          this.$router.push('/')
          break
        case '2':
        // console.log("Notifications Click");
          this.$emit('toggle-side-menu-from-sidebar')
          this.selectedKey = ['2']
          // window.open('https://university.dashmote.com/', '_blank')
          this.$router.push('/university')
          break
        case '3':
          this.selectedKey = ['3']
          this.goSetting()
          break
        case '4':
          this.selectedKey = ['4']
          this.logOut()
          break
      }
    },
    logOut () {
      this.$emit('toggle-side-menu-from-sidebar')
      firebase.auth().signOut()

      localStorage.removeItem('userEmail')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('displayName')
      localStorage.removeItem('userProfileImageUrl')
      localStorage.removeItem('userTrackingProfileStatus')
      localStorage.removeItem('userReference')

      this.$intercom.shutdown()
      this.$intercom.boot()

      this.$router.push('/login')
    },
    goSetting () {
      this.$emit('toggle-side-menu-from-sidebar')
      // Track open account settings for Mixpanel
      this.$mixpanel.track('Open account settings')
      this.$intercom.trackEvent('trackEvent', 'Open account settings')
      this.$router.push('/account')
    },
    highlightDashmoteUniversityItem () {
      this.selectedKey = ['2']
    },
    hide () {
      this.$emit('toggle-side-menu-from-sidebar')
    }
  }
}
</script>

<style scoped>
@import './sidebar.css';
</style>
