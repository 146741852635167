<template>
  <div class="project-wrapper" v-if="!mobile()">
    <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
    <a-row>
      <div class="header-2" style="margin-top: 38px">My Workspaces</div>
      <p class="paragraph-large" style="margin-bottom: 60px">
        Here you can find your workspaces and dashboards inside.
      </p>
    </a-row>
    <a-row>
      <a-col>
        <div class="project-search-style">
        <a-row class="search-style" style="margin-bottom: 20px">
          <span class="header-3">Sort workspaces by:</span>
          <span class="project-sort-button" :class="{activeAscending: this.sortType === 'nameAsc', activeDescending: this.sortType === 'nameDsc'}" v-on:click="onSort('name')"><span class="button-text">Name</span><img class="sortingArrow" style="margin-left: 8px; width: 12px;"></span>
          <span class="project-sort-button" :class="{activeAscending: this.sortType === 'dateAsc', activeDescending: this.sortType === 'dateDsc'}" v-on:click="onSort('date')"><span class="button-text">Date added</span><img class="sortingArrow" style="margin-left:8px; width: 12px;"></span>
          <a-input-search
            placeholder="Search"
            style="width: 274px; margin-top:0px; font-weight: normal;"
            class="project-input-search"
            @change="onSearch"
          />
        </a-row></div>
        <a-row>
          <div class="contentarea" >
          <a-col v-if="!filterData" :span="19" class="empty-card-area">
            <img style="display: block; margin-left: auto; margin-right: auto; width: 293px; margin-top:54px" :src="require('../../assets/noProjectsImage.svg')">
            <div class="noProjectsTitle" >No workspaces to see yet.</div>
            <div class="noProjectsText" >Soon Dashmote will unlock your first workspace. <br>Contact your account manager for more info.</div>
          </a-col>
          <a-col v-else class="project-card-area" style="margin-right:8px;">
            <ShowProjectCard
              v-for="(key, item) in filterData"
              :key="item['name']"
              icon="grid-outline"
              :value="key[1]"
              :projectId="key[0]"
            />
          </a-col>
          <div class="rightcard" >
          <a-col class="project-card-slide">
      <ProjectSlideCard
        :category="projectslidecards[Object.keys(projectslidecards)[0]].category"
        :cardContent="projectslidecards[Object.keys(projectslidecards)[0]].title"
        :backgroundcolor="projectslidecards[Object.keys(projectslidecards)[0]].backgroundcolor"
        :iconbackgroundcolor="projectslidecards[Object.keys(projectslidecards)[0]].iconbackgroundcolor"
        :linkurl="projectslidecards[Object.keys(projectslidecards)[0]].linkurl"
        cardKey="1"
      />
      <ProjectSlideCard
        :category="projectslidecards[Object.keys(projectslidecards)[1]].category"
        :cardContent="projectslidecards[Object.keys(projectslidecards)[1]].title"
        :backgroundcolor="projectslidecards[Object.keys(projectslidecards)[1]].backgroundcolor"
        :iconbackgroundcolor="projectslidecards[Object.keys(projectslidecards)[1]].iconbackgroundcolor"
        :linkurl="projectslidecards[Object.keys(projectslidecards)[1]].linkurl"
        cardKey="2"
      />
      <ProjectSlideCard
        :category="projectslidecards[Object.keys(projectslidecards)[2]].category"
        :cardContent="projectslidecards[Object.keys(projectslidecards)[2]].title"
        :backgroundcolor="projectslidecards[Object.keys(projectslidecards)[2]].backgroundcolor"
        :iconbackgroundcolor="projectslidecards[Object.keys(projectslidecards)[2]].iconbackgroundcolor"
        :linkurl="projectslidecards[Object.keys(projectslidecards)[2]].linkurl"
        cardKey="3"
      />
          </a-col>
          </div>
          </div>
        </a-row>
      </a-col>
    </a-row>
  </div>
  <div v-else>
    <MobileProjects :data="cardData" :name="name" />
  </div>
</template>

<script>
import ShowProjectCard from './ShowProjectCard.vue'
import ProjectSlideCard from './ProjectSlideCard.vue'
import MobileProjects from './MobileProjects.vue'
import firebase from 'firebase'
import moment from 'moment'
import { db } from '../../firebase'
var CryptoJS = require('crypto-js')
const R = require('ramda')
const mobile = require('is-mobile')

var defaultState = {
  1: { iconname: 'cart', backgroundcolor: '#D3D6E3', iconbackgroundcolor: '#D3D6E3', title: '', linkurl: '' },
  2: { iconname: 'cart', backgroundcolor: '#D3D6E3', iconbackgroundcolor: '#D3D6E3', title: '', linkurl: '' },
  3: { iconname: 'cart', backgroundcolor: '#D3D6E3', iconbackgroundcolor: '#D3D6E3', title: '', linkurl: '' }
}

export default {
  name: 'Projects',
  components: {
    ShowProjectCard,
    ProjectSlideCard,
    MobileProjects
  },
  data () {
    return {
      mobile,
      overlay: false,
      projectslidecards: defaultState,
      cardData: {
        loadingProjectKey1: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } },
        loadingProjectKey2: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } },
        loadingProjectKey3: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } },
        loadingProjectKey4: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } },
        loadingProjectKey5: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } },
        loadingProjectKey6: { name: 'Loading', createdAt: '0001-01-01', dashboards: { loadingDashboardKey: { name: 'LoadingDashboard' } } }
      },
      search: '',
      proIdList: [],
      name: this.getDecryptedEmail(),
      sortType: undefined,
      nameSortDirection: 'ascent',
      dateSortDirection: 'descent'
    }
  },
  methods: {
    getProjects () {
      const self = this
      const getUserProjectsByEmail = firebase
        .functions()
        .httpsCallable('getUserProjectsByEmail')
      getUserProjectsByEmail(self.getDecryptedEmail())
        .then((response) => {
          self.cardData = response.data
        })
    },

    getCardsFromDB () {
      // Get cards
      return db.ref('/projectslidecards').once('value').then(function (snapshot) {
        const cards = snapshot.val()
        return cards
      })
    },

    // Sorting function
    onSort (type) {
      // Sorting on name
      if (type === 'name') {
        if (this.nameSortDirection === 'ascent') {
          this.sortType = 'nameAsc'
          this.nameSortDirection = 'descent'
        } else if (this.nameSortDirection === 'descent') {
          this.sortType = 'nameDsc'
          this.nameSortDirection = 'ascent'
        }
      // Sorting on dateAdded
      } else if (type === 'date') {
        if (this.dateSortDirection === 'ascent') {
          this.sortType = 'dateAsc'
          this.dateSortDirection = 'descent'
        } else if (this.dateSortDirection === 'descent') {
          this.sortType = 'dateDsc'
          this.dateSortDirection = 'ascent'
        }
      }
    },
    onSearch (e) {
      this.search = e.target.value
    },
    isContain (item) {
      if (this.search && this.search !== undefined) {
        let isRight
        try {
          isRight = item.name.toLowerCase().includes(this.search.toLowerCase())
        } catch (err) {
          console.log(err)
        }
        return isRight
      } else {
        return true
      }
    },
    createTrackingProfile (email) {
      this.$mixpanel.identify(email)
      this.$mixpanel.people.set({
        $email: email // only reserved properties need the $
      })

      // Set trackingProfile status to true.
      localStorage.setItem('userTrackingProfileStatus', 'true')
      this.$store.commit('setUserTrackingProfileStatus', 'true')
    },
    setLastSeen () {
      const self = this
      var lastSeenData = {
        lastSeen: moment().format()
      }
      const getUserByEmail = firebase
        .functions()
        .httpsCallable('getUserByEmail')
      getUserByEmail(self.getDecryptedEmail())
        .then((response) => {
          db.ref(`users/${R.path(['data', 'uid'], response)}`)
            .update(lastSeenData)
            .then(() => {
              // console.log('success')
            })
            .catch((error) => {
              console.log('ERROR', error.toString())
            })
        })
    },
    timoutForNoData () {
      if (this.filterData[0][0] === 'loadingProjectKey1' || this.filterData[0][0] === 'loadingProjectKey2' ||
          this.filterData[0][0] === 'loadingProjectKey3' || this.filterData[0][0] === 'loadingProjectKey4' ||
          this.filterData[0][0] === 'loadingProjectKey5' || this.filterData[0][0] === 'loadingProjectKey6') {
        console.log('loading too long')
        this.cardData = 'empty'
      }
    },
    getDecryptedEmail () {
      // Check if there is already an userEmail stored
      if (localStorage.getItem('userEmail')) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem('userEmail')), process.env.VUE_APP_AES_PASSPHRASE).toString()
        localStorage.setItem('userReference', ciphertext)
        localStorage.removeItem('userEmail')
      }
      // decrypt item
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userReference'), process.env.VUE_APP_AES_PASSPHRASE)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    }
  },
  computed: {
    // search use name
    filterData () {
      if (this.cardData === 'empty') {
        return ''
      }

      // Convert to Array of Arrays after filtering.
      let filteredData = Object.entries(R.filter(this.isContain)(this.cardData))
      switch (this.sortType) {
        case '':
          break
        case 'nameAsc':
          filteredData = filteredData.sort((a, b) => a[1].name.localeCompare(b[1].name))
          break
        case 'nameDsc':
          filteredData = filteredData.sort((a, b) => a[1].name.localeCompare(b[1].name)).reverse()
          break
        case 'dateAsc':
          filteredData = filteredData.sort((a, b) => a[1].createdAt - b[1].createdAt)
          break
        case 'dateDsc':
          filteredData = filteredData.sort((a, b) => a[1].createdAt - b[1].createdAt).reverse()
          break
      }
      // console.log(filteredData)
      // return '' // Set to test the no Projects Screen
      return filteredData
    }
  },
  mounted () {
    setTimeout(() => this.timoutForNoData(), 10000) // 10 seconds timout should be safe.
  },
  async created () {
    this.getProjects()
    this.projectslidecards = await this.getCardsFromDB()
    this.setLastSeen()
    this.onSort('date')
    if (!localStorage.getItem('accessToken')) {
      this.$router.push('/login')
    } else {
      if (!localStorage.getItem('displayName')) {
        var user = firebase.auth().currentUser
        if (user) {
          // User is signed in.
          localStorage.setItem('displayName', user.displayName)
          localStorage.setItem('userProfileImageUrl', user.photoURL)
          // console.log('created display profile')
        } else {
          // No user is signed in.
        }
      }
      if (!localStorage.getItem('userTrackingProfileStatus')) {
        // console.log('created tracking profile')
        this.createTrackingProfile(this.getDecryptedEmail())
      }
    }
  }
}
</script>
<style scoped>
@import "./Projects.css";
</style>
