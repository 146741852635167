<template>
    <div style="margin: 0 6%;">
      <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
        <a-row class="header-1" style="color: #223273;">
            Hello {{ name.split('@')[0] }}!
        </a-row>
        <a-row class="mobile-paragraph-large row-space-8">
            Here you can find your workspaces and dashboards inside.
        </a-row>
        <a-row class="search-style row-space-16" style="margin-left: -14px;">
            <a-input-search placeholder="Search" style="margin-left: 14px;font-weight: normal;" class="project-input-search-mobile" @change="onSearch" />
        </a-row>
        <a-row class="row-space-56">
            <div class="header-3">Sort projects by:</div>
            <span class="project-sort-button-mobile" :class="{activeAscendingMobile: this.sortType === 'nameAsc', activeDescendingMobile: this.sortType === 'nameDsc'}" v-on:click="onSort('name')">Name <img class="sortingArrow" style="margin-left: 8px; width: 12px;"></span>
            <span class="project-sort-button-mobile" :class="{activeAscendingMobile: this.sortType === 'dateAsc', activeDescendingMobile: this.sortType === 'dateDsc'}" v-on:click="onSort('date')">Date added <img class="sortingArrow" style="margin-left:8px; width: 12px;"></span>
        </a-row>
        <a-row class="mobile-project-card-area row-space-32">
            <ShowProjectCard
                v-for="(key, item) in filterData"
                :key="item['name']"
                icon="grid-outline"
                :value="key[1]"
                :projectId="key[0]"
            />
        </a-row>
    </div>
</template>
<script>
import ShowProjectCard from './ShowProjectCard.vue'
const R = require('ramda')
export default {
  name: 'MobileProjects',
  components: {
    ShowProjectCard
  },
  props: ['data', 'name'],
  data () {
    return {
      search: '',
      overlay: false,
      sortType: undefined,
      nameSortDirection: 'ascent',
      dateSortDirection: 'descent'
    }
  },
  methods: {
    onSort (type) {
      // Sorting on name
      if (type === 'name') {
        if (this.nameSortDirection === 'ascent') {
          this.sortType = 'nameAsc'
          this.nameSortDirection = 'descent'
        } else if (this.nameSortDirection === 'descent') {
          this.sortType = 'nameDsc'
          this.nameSortDirection = 'ascent'
        }
      // Sorting on dateAdded
      } else if (type === 'date') {
        if (this.dateSortDirection === 'ascent') {
          this.sortType = 'dateAsc'
          this.dateSortDirection = 'descent'
        } else if (this.dateSortDirection === 'descent') {
          this.sortType = 'dateDsc'
          this.dateSortDirection = 'ascent'
        }
      }
    },
    onSearch (e) {
      this.search = e.target.value
    },
    isContain (item) {
      if (this.search && this.search !== undefined) {
        let isRight
        try {
          isRight = item.name.toLowerCase().includes(this.search.toLowerCase())
        } catch (err) {
          console.log(err)
        }
        return isRight
      } else {
        return true
      }
    }
  },
  // Sorting function

  computed: {
    // search use name
    filterData () {
      // Convert to Array of Arrays after filtering.
      let filteredData = Object.entries(R.filter(this.isContain)(this.data))
      switch (this.sortType) {
        case '':
          break
        case 'nameAsc':
          filteredData = filteredData.sort((a, b) => a[1].name.localeCompare(b[1].name))
          break
        case 'nameDsc':
          filteredData = filteredData.sort((a, b) => a[1].name.localeCompare(b[1].name)).reverse()
          break
        case 'dateAsc':
          filteredData = filteredData.sort((a, b) => a[1].createdAt - b[1].createdAt)
          break
        case 'dateDsc':
          filteredData = filteredData.sort((a, b) => a[1].createdAt - b[1].createdAt).reverse()
          break
      }

      return filteredData
    }
  },
  created () {
    this.onSort('date')
  }
}
</script>
<style scoped>
@import './Projects.css';
@import '../Dashboards/DetailPage.css';
</style>
