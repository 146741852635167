<template>
    <div v-if="!mobile()">
        <a-row class="dashboard-subtitle-row detail-content">
            <img :src="require('../../assets/icon_grid_filled.svg')" style="vertical-align: -8px; margin-left: 26px; margin-right: 16px; margin-top: 21px;" alt="">
            <span style="color: #373B53;" class="header-4">{{ name }}</span>
            <a-input-search placeholder="Search for an activity" style="width: 270px; float: right;line-height: 66px;margin-right: 20px;" @change="onSearch" />
        </a-row>
        <div v-if="!allowAccess" class="failedLoadingPage" style="margin-top:5%"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">You are not allowed to access this project.</div><br></div></div>
        <a-row v-else class="row-space-8" style="height: 500px;">
            <ActivitiesTable
                :data="filterTableData"
                :columns="columns"
            />
        </a-row>
    </div>

    <div v-else>
        <a-row class="dashboard-subtitle-row detail-content">
            <img :src="require('../../assets/icon_grid_filled.svg')" style="vertical-align: -8px; margin-left: 26px; margin-right: 16px; margin-top: 21px;" alt="">
            <span style="color: #373B53;" class="header-4">{{ name }}</span>
            <a-input-search placeholder="Search for an activity" style="width: 250px; float: right;line-height: 66px; margin-left: 20px; margin-right: 20px;" @change="onSearch" />
        </a-row>
        <div v-if="!allowAccess" class="failedLoadingPage" style="margin-top:5%"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">You are not allowed to access this project.</div><br></div></div>
        <a-row v-else class="row-space-8 mobile-users-card-area" style="height: 520px; overflow-y: scroll;">
            <ActivitiesTableMobile
                :data="filterTableData"
                :columns="mobileColumns"
            />
        </a-row>
    </div>
</template>
<script>
import ActivitiesTable from './ActivitiesTable.vue'
import ActivitiesTableMobile from './ActivitiesTableMobile.vue'
import firebase from 'firebase'
import { db } from '../../firebase'
import { values, keys, path } from 'ramda'
const R = require('ramda')
var CryptoJS = require('crypto-js')
const mobile = require('is-mobile')
const columns = [
  {
    title: 'Activity',
    dataIndex: 'value',
    width: '70%',
    scopedSlots: { customRender: 'column1' },
    ellipsis: 'true'
  },
  {
    title: 'Last updated',
    dataIndex: 'createdAt',
    scopedSlots: { customRender: 'column2' }
  }
]

const mobileColumns = [
  {
    title: 'Activity',
    dataIndex: 'value',
    width: '40%',
    scopedSlots: { customRender: 'column1' }
  }
]

export default {
  name: 'Activity',
  components: {
    ActivitiesTable,
    ActivitiesTableMobile
  },
  data () {
    return {
      mobile,
      cardData: [
        { createdAt: 'Loading', value: 'Loading' },
        { createdAt: 'Loading', value: 'Loading' },
        { createdAt: 'Loading', value: 'Loading' },
        { createdAt: 'Loading', value: 'Loading' },
        { createdAt: 'Loading', value: 'Loading' }
      ],
      columns,
      mobileColumns,
      search: '',
      name: '',
      activitiesList: [],
      allowAccess: true,
      dashmoteUser: false
    }
  },
  methods: {
    onSearch (e) {
      this.search = e.target.value
    },
    getData () {
      const self = this
      // debugger
      db.ref('projects/' + self.$route.params.id).once('value', snapshot => {
        if (R.has('name')(snapshot.val())) {
          self.name = snapshot.val().name
        }
        if (R.has('activities')(snapshot.val())) {
          self.activitiesList = keys(R.filter((item) => {
            if (item === true) { return true } else { return false }
          })(snapshot.val().activities))
        }
      }).then(() => {
        db.ref('activities/').once('value', snapshot => {
          self.cardData = R.map((item) => {
            item.projectName = self.name
            return item
          })(values(R.pickBy((item, key) => {
            if (self.activitiesList.indexOf(key) !== -1) { return true } else { return false }
          })(snapshot.val())))
        })
      })
    },
    getDecryptedEmail () {
      // Check if there is already an userEmail stored
      if (localStorage.getItem('userEmail')) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem('userEmail')), process.env.VUE_APP_AES_PASSPHRASE).toString()
        localStorage.setItem('userReference', ciphertext)
        localStorage.removeItem('userEmail')
      }
      // decrypt item
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userReference'), process.env.VUE_APP_AES_PASSPHRASE)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      return decryptedData
    },
    checkPermission () {
      // Check here if the user has permission to access this dashboard.
      const self = this
      const getUserByEmail = firebase
        .functions()
        .httpsCallable('getUserByEmail')
      getUserByEmail(self.getDecryptedEmail())
        .then((response) => {
          db.ref(`users/${path(['data', 'uid'], response)}/projects/${self.$route.params.id}/`)
            .once('value', (snapshot) => {
              if (snapshot.val()) {
                self.allowAccess = true
              } else {
                if (this.dashmoteUser) {
                  self.allowAccess = true
                } else {
                  self.allowAccess = false
                }
              }
            })
        })
    },
    checkIsDashmoteUser () {
      const localemail = this.getDecryptedEmail()
      const emailPostfix = localemail.split('@')[1]
      if (emailPostfix === 'dashmote.com') {
        this.dashmoteUser = true
      } else {
        this.dashmoteUser = false
      }
    }
  },
  computed: {
    filterTableData () {
      return R.filter(item => {
        const taglist = []
        if (item.value.toLowerCase().includes(this.search.toLowerCase())) {
          taglist.push('true')
        } else {
          taglist.push('false')
        }
        if (taglist.indexOf('true') !== -1) {
          return true
        } else {
          return false
        }
      })(this.cardData)
    }
  },
  created () {
    this.checkIsDashmoteUser()
    this.checkPermission()
    this.getData()
  }
}
</script>
<style scoped>
@import './DetailPage.css';
@import '../Projects/Projects.css';
</style>
