import Vue from 'vue'
import Router from 'vue-router'
import DetailPage from '../pages/Dashboards/DetailPage.vue'
import Projects from '../pages/Projects/Projects.vue'
import Login from '../pages/Login/Login.vue'
import ForgotPassword from '../pages/Login/ForgotPassword.vue'
import ResetPassword from '../pages/Login/ResetPassword.vue'
// import Notifications from '../pages/Notifications/Notifications.vue'
import Account from '../pages/Account/Account.vue'
import University from '../pages/University/University.vue'
import PowerbiPage from '../pages/Dashboards/PowerbiPage.vue'
import ColumboPage from '../pages/Dashboards/ColumboPage.vue'
import DashboardRedirect from '../pages/Dashboards/DashboardRedirect.vue'
import DashboardSettings from '../pages/Dashboards/Settings/DashboardSettings.vue'
import SingleOutletPageComponent from '../pages/Dashboards/SingleOutletPageComponent.vue'
// import AnalyticsTool from '../pages/Dashboards/AnalyticsTool.vue'

Vue.use(Router)
const routes = [{
  path: '/',
  component: Projects,
  meta: {
    title: 'Projects',
    requireAuth: true
  }
},
/*
{
  path: '/notifications',
  component: Notifications,
  meta: {
    title: 'Notifications',
    requireAuth: true
  }
},
*/
{
  path: '/forgotpassword',
  name: 'ForgotPassword',
  component: ForgotPassword
},
{
  path: '/resetpassword',
  name: 'ResetPassword',
  component: ResetPassword
},
{
  path: '/outlet/:projectId/:dashboardId/:outletId',
  component: SingleOutletPageComponent,
  meta: {
    title: 'SingleOutletPage',
    requireAuth: true
  }
},
/* Disable path to the Analystics tool for nice since we did not finalize this yet.
{
  path: '/analytics/:id',
  component: AnalyticsTool,
  meta: {
    title: 'AnalyticsTool',
    requireAuth: true
  }
},
*/
{
  path: '/project/:id',
  component: DetailPage,
  meta: {
    title: 'DetailPage',
    requireAuth: true
  }
},
{
  path: '/dashboard/projects',
  component: Projects,
  meta: {
    title: 'Projects',
    requireAuth: true
  }
},
{
  path: '/project/:projectId/dashboard/:dashboardId/settings',
  component: DashboardSettings,
  meta: {
    title: 'DashboardSettings',
    requireAuth: true
  }
},
{
  path: '/project/:projectId/dashboard/:dashboardId/settings/:page',
  component: DashboardSettings,
  meta: {
    title: 'DashboardSettings',
    requireAuth: true
  }
},
{
  path: '/dashboard/projects/:projectId/:dashboardId/dashboard',
  component: DashboardRedirect,
  meta: {
    title: 'DashboardRedirect',
    requireAuth: true
  }
},
{
  path: '/project/:projectId/:dashboardId',
  component: DashboardRedirect,
  meta: {
    title: 'DashboardRedirect',
    requireAuth: true
  }
},
{
  path: '/dashboard/projects/:id/details',
  component: DetailPage,
  meta: {
    title: 'DetailPage',
    requireAuth: true
  }
},
{
  path: '/project/:projectId/forge/:dashboardId',
  component: PowerbiPage,
  meta: {
    title: 'PowerbiPage',
    requireAuth: true
  }
},
{
  path: '/project/:projectId/columbo/:dashboardId',
  component: ColumboPage,
  meta: {
    title: 'ColumboPage',
    requireAuth: true
  }
},
{
  path: '/login',
  name: 'Login',
  component: Login
},
{
  path: '/account',
  component: Account,
  meta: {
    title: 'Account',
    requireAuth: true
  }
},
{
  path: '/university/:pathMatch(.*)*',
  component: University,
  meta: {
    title: 'University',
    requireAuth: true
  }
}
]

const router = new Router({
  mode: 'history',
  routes
})

export default router
