<template>
<div>
  <a-row>
    <!-- For small screen sizes under 980px width / mobile -->
    <a-col v-if="this.smallscreen" class="login-left" :span="24">
      <a-row>
        <a-col :span="4"></a-col>
        <a-col
          :span="18"
          class="font-mobile login-width-mobile login-left-width"
        >
        <img class="login-logo-mobile" :src="require('../../assets/dashmote-logo-white.svg')" alt="">
          <div>
            <div class="header-1" style="margin-top: 75px; margin-bottom: 100px">
              Welcome to Dashmote!
            </div>
          </div>
          <a-row class="header-4">Please enter your email.</a-row>
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item>
              <a-input style="padding:16px;"
                v-decorator="[
                  'email',
                  {
                    rules: [
                      {
                        type: 'email',
                        message: 'Email format wrong',
                      },
                      {
                        required: true,
                        message: 'Please input your Email!',
                      },
                    ],
                  },
                ]"
                placeholder="Email"
                ref="email"
                @keyup.enter="login"
              />
            </a-form-item>

          </a-form>
          <a-row>
            <span @click="goToLogin()" style="color: #1990FF; cursor: pointer;" class="dash-text-button">Go back to login</span>
            <span @click="$intercom.show()" class="login-help-font">I need some help</span>
          </a-row>
          <a-button v-if="!success"
            class="primary-button-style-total row-space-16"
            style="width: 100%; top: 16px; bottom: 16px; margin-bottom: 140px"
            @click="login"
            >Send password recovery email</a-button
          >
          <a-button v-if="success"
            disabled
            class="primary-button-style-total row-space-16"
            style="width: 100%; top: 16px; bottom: 16px; margin-bottom: 140px"
            @click="login"
            >Send password recovery email</a-button
          >
          <div class="copyright-text-mobile">© Dashmote 2021</div>
        </a-col>
        <a-col :span="2"></a-col>
      </a-row>
    </a-col>

    <!-- For normal screen sizes above 980px width / desktop -->
    <a-col v-else class="login-left" :span="12">
      <img class="login-logo" :src="require('../../assets/dashmote-logo.svg')" alt="">
      <a-row>
        <a-col :span="4"></a-col>
        <a-col
          :span="18"
          class="font-mobile login-width-mobile login-left-width"
        >
          <div>
            <div class="header-1 mobile-margin-top title-top">
              Welcome to Dashmote,
            </div>
            <div class="header-1 title-bottom">
              let's start your data journey!
            </div>
          </div>
          <a-row class="header-4">Please enter your email.</a-row>
          <a-form :form="form" @submit="handleSubmit">
            <a-form-item>
              <a-input style="padding:16px;"
                v-decorator="[
                  'email',
                  {
                    rules: [
                      {
                        type: 'email',
                        message: 'Email format wrong',
                      },
                      {
                        required: true,
                        message: 'Please input your Email!',
                      },
                    ],
                  },
                ]"
                placeholder="Email"
                ref="email"
                @keyup.enter="login"
              />
            </a-form-item>

          </a-form>
          <a-row>
            <span @click="goToLogin()" style="color: #1990FF; cursor: pointer;" class="dash-text-button">Go back to login</span>
            <span @click="$intercom.show()" class="login-help-font">I need some help</span>
          </a-row>
          <a-button v-if="!success"
            class="primary-button-style-total row-space-16 send-recovery-password-button"
            style="width: 100%; top: 16px;"
            @click="login"
            >Send password recovery email</a-button
          >
          <a-button v-if="success"
            disabled
            class="primary-button-style-total row-space-16 send-recovery-password-button"
            style="width: 100%; top: 16px;"
            @click="login"
            >Send password recovery email</a-button
          >
        </a-col>
        <a-col :span="2"></a-col>
      </a-row>
       <div class="copyright-text">© Dashmote 2021</div>
    </a-col>
    <!-- Right side of desktop login page -->
    <a-col class="login-right-block" :span="12">
            <div class="top-all-picture"></div>
      <div class="bottom-bgc"></div>
    </a-col>
  </a-row>
</div>

</template>
<script>
import firebase from 'firebase'
const mobile = require('is-mobile')
export default {
  name: 'Login',
  data () {
    return {
      mobile,
      success: false,
      smallscreen: window.innerWidth <= 980,
      email: ''
    }
  },
  beforeCreate () {
    this.form = this.$form.createForm(this, { name: 'register' })
  },

  created () {
    // Check if the client is using a mobile device or not, based on the screen width.
    addEventListener('resize', () => {
      this.smallscreen = innerWidth <= 980
    })
  },

  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values)
        }
      })
    },
    goToLogin () {
      // console.log('resetpassword')
      this.$router.push('/login')
    },
    forgotPassword (email) {
      var auth = firebase.auth()
      var emailAddress = email
      var self = this

      const emailPostfix = emailAddress.split('@')[1]
      if (emailPostfix !== 'dashmote.com') {
        this.forgotPasswordText = 'Password recovery email has been send.' // Make this better later

        auth.sendPasswordResetEmail(emailAddress).then(function () {
        // Email sent.
          console.log('password recovery email send')
          self.success = true
          self.succesMessage()
        }).catch(function (error) {
        // An error happened.
          console.log(error)
          self.$error({
            title: 'User does not exist or an error happened. Please try again.'
          })
        })
      } else {
        self.$error({
          title: '\'@dashmote.com\' users should request support to reset their password.'
        })
      }
    },
    // Create user tracking profile
    createTrackingProfile (email) {
      this.$mixpanel.identify(email)
      this.$mixpanel.people.set({
        $email: email // only reserved properties need the $
      })

      // Set trackingProfile status to true.
      localStorage.setItem('userTrackingProfileStatus', 'true')
      this.$store.commit('setUserTrackingProfileStatus', 'true')
    },

    goToForgotPassword () {
      // console.log('forgot password')
      this.$router.push('/forgotpassword')
    },
    succesMessage () {
      this.$message.success({ content: 'Recovery email has been sent! Going back to login...' })
      setTimeout(() => {
        this.goToLogin()
      }, 1000)
    },

    errorMessage (errorText) {
      this.$message.error(errorText)
    },
    login () {
      const email = this.$refs.email.stateValue
      // const pwd = this.$refs.pwd.$refs.input.stateValue
      if (email) {
        if (this.$refs.email.stateValue.indexOf('@dashmote.com') !== -1) {
          // Dashmote users don't really need recovery email. Send a message to user about this
          this.forgotPassword(email)
        } else { // Do send recovery email
          this.forgotPassword(email)
        }
      }
    }
  }
}
</script>
<style scoped>
@import "./Login.css";
@import "./LoginMobile.css";
@import "../Projects/Projects.css";
@import "../Dashboards/DetailPage.css";
</style>
