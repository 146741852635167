<template>
  <div v-if="pageData.outlet_name" style="width:100%;">
    <v-overlay :value="overlay" color="#223273" opacity="0.15"></v-overlay>
    <a-row v-if="allowAccess" class="row outletcontent">
      <a-row v-if="isComponent" class="navigationrow">
        <!-- Refactor background color later -->
        <div style="position: absolute; left: -12%; top: 0px; height: 64px; width: 130%; background: #D1E9FF;">.</div>
        <div class="page-navigation"><span @click="goMainPage" class="project-overview-link"><img style="margin-right: 8px;" :src="require('../../assets/arrowLeft.svg')"/><span style="border-bottom: 1px solid #1990FF;">Back to Dashboard</span></span></div>
      </a-row>
      <a-row v-else style="height: 62px;">.</a-row>
      <a-row class="basicsrow">
        <a-modal width=375px v-model="visible" title="Share this outlet" :footer="null">
          <p><img style="margin-right: 18px;" :src="require('../../assets/singleoutleticon/share_url_icon.svg')"/><a @click="copyPageURL">Copy outlet page URL</a></p>
          <p><img style="margin-right: 18px;" :src="require('../../assets/singleoutleticon/share_googlemaps_icon.svg')"/><a @click="copyGoogleMapsURL">Copy Google Maps URL</a></p>
        </a-modal>
      <img style="float:left; position: relative; margin-right:24px;box-shadow: 0px 4px 10px rgba(120, 120, 120, 0.1), 0px 4px 5px rgba(151, 151, 151, 0.1);border-radius: 8px;background: #FFF3C1;" :src="require('../../assets/singleoutleticon/icon_cafe.svg')"/><b class="outlettitle">{{ pageData.outlet_name }}</b>
          <br/><span class="dash-header-four">{{ pageData.outlet_review_score}}</span><a-rate style="margin-left:9px; margin-right:16px;" v-model="pageData.outlet_review_score" disabled allow-half /><span class="dash-header-four">({{ pageData.outlet_reviews_nr }})</span> <span class="dash-header-four">•</span> <b class="dash-header-four">{{ pageData.outlet_price }}</b><span style="float: right;"><a-button class="primary-button-style-total sharebutton" @click="openShareModal"><img class="arrow-image-share" src="../../assets/singleoutleticon/share_upload_icon.svg" style="margin-right: 8px; margin-bottom:3px;">Share</a-button></span>
      </a-row>
      <a-row class="basicsrow" style="margin-top: 48px;">
        <a-col :span="8">
          <a-tag disabled v-if="pageData.franchise" style="margin-left:4px; color: #00CC74; background: #CCF5E3; border: 0.5px solid #00CC74;">Franchise<img style="margin-left: 4px; height: 14px;" :src="require('../../assets/singleoutleticon/icon_check.svg')"/></a-tag>
          <a-tag disabled v-else style="margin-left:4px; color: #DD1155; background: #F3A9C2; border: 0.5px solid #DD1155;">No Franchise<img style="margin-left: 8px;" :src="require('../../assets/singleoutleticon/icon_cross.svg')"/></a-tag>
          <a-tag disabled v-for="item of pageData.outlet_types" :key="String(item)" style="margin-left:4px; color: #1990FF; background: #D1E9FF; border: 0.5px solid #1990FF;">{{ item }}</a-tag><br/><br/>
          <img style="margin-right: 16px;" :src="require('../../assets/singleoutleticon/icon_mappin.svg')"/><a :href="'https://www.google.com/maps/search/?api=1&query='+pageData.outlet_name+'+'+pageData.outlet_address" target="_blank">{{ pageData.outlet_address }}</a>
          <br/><br/><img style="margin-right: 16px;" :src="require('../../assets/singleoutleticon/icon_telephone.svg')"/>
            <span v-if="pageData.phone_numbers">{{ pageData.phone_numbers[0] }}</span>
            <span v-else>No phone number available</span>
          <br/><br/><div style="float: left; color: #1990FF; width: 300px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden !important;"><img style="margin-right: 16px;" :src="require('../../assets/singleoutleticon/icon_address.svg')"/>
            <a v-if="pageData.website_urls" :href="pageData.website_urls[0]" target="_blank">{{ pageData.website_urls[0] }}</a>
            <span v-else>No website available</span></div>
          <br/><br/><span v-if="pageData.opening_hours"><img style="margin-right: 16px;" :src="require('../../assets/singleoutleticon/icon_openingtimes.svg')"/>{{ this.getOpeningTimeToday(pageData.opening_hours) }}
          <a class="dash-text-button" style="margin-left:24px;">
          <a-tooltip placement="bottomLeft">See more
            <template slot="title">
              <span>Mon: {{pageData.opening_hours.opening_hrs_mon}}</span><br/>
              <span>Tue: {{pageData.opening_hours.opening_hrs_tue}}</span><br/>
              <span>Wed: {{pageData.opening_hours.opening_hrs_wed}}</span><br/>
              <span>Thu: {{pageData.opening_hours.opening_hrs_thu}}</span><br/>
              <span>Fri: {{pageData.opening_hours.opening_hrs_fri}}</span><br/>
              <span>Sat: {{pageData.opening_hours.opening_hrs_sat}}</span><br/>
              <span>Sun: {{pageData.opening_hours.opening_hrs_sun}}</span>
            </template>
          </a-tooltip></a>
          </span>
          <br/><br/><div style="float: left; color: #1990FF; width: 152px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden !important;"><img style="margin-right: 16px;" :src="require('../../assets/singleoutleticon/icon_id.svg')"/><a-tooltip :title="pageData.outlet_id"> {{ pageData.outlet_id}} </a-tooltip></div><span style="cursor: pointer" @click="copyOutletID"><img style="margin-left: 16px; margin-right: 10px;" :src="require('../../assets/singleoutleticon/icon_copy.svg')"/><a class="dash-text-button">Copy</a></span>
          <br/><br/><br/>
        </a-col>
        <a-col :span="16">
          <div class="maparea"><iframe
  width=100%
  height=100%
  frameborder="0" style="border:0;"
  :src="pageData.map_embed_src" allowfullscreen>
</iframe></div>
          </a-col>
      </a-row>
      <a-divider />
      <a-row class="outletrow">
        <a-col :span="8">
          <b class="dash-header-four" style="/Text Grey */ color: #5C6075;">Outlet Details</b>
          </a-col>
        <a-col :span="16">
          <a-row :gutter="16">
            <a-col :span="8">
              <a-card class="card scrollable-card" title="Cuisine Types" :bordered="false">
                <p v-if="pageData.cuisine_types.length > 0">
                  <a-tag disabled v-for="item of pageData.cuisine_types" :key="String(item)" style="color:#1990FF; background:#D1E9FF; border: 1px solid #1990FF; margin-top:8px; margin-right:8px;">{{ item }}</a-tag>
                </p>
                <p v-else>No Cuisine Types found</p>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card class="chart-card" title="Brand Portfolio" :bordered="false">
                <pie-chart v-if="pageData.brands.length > 0" style="height: 100%; width: 100%;" v-bind="config"/>
                <p style="margin-left: 95px; margin-top: 20px;" v-else>No brands found</p>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card class="card scrollable-card" title="Meal Delivery Platforms" :bordered="false">
                <p v-if="pageData.actual_delivery_platforms.length > 0">
                  <!-- <img style="margin-right: 5px" width="30px" :src="require('../../assets/singleoutleticon/platforms/platform_uber_eats.png')"/> -->
                  <a-tag @click="goToSource(item.platform_url)" disabled v-for="item of pageData.actual_delivery_platforms" :key="String(item.platform)" :class="{ 'source-tag-with-link': item.platform_url, 'source-tag-without-link': !item.platform_url }" >{{ item.platform }}<img v-if="item.platform_url" style="margin-left: 5px;" :src="require('../../assets/singleoutleticon/arrow_diagonal_up_right_purple.svg')"/></a-tag>
                </p>
                <p v-else >No Delivery Platforms found</p>
              </a-card>
            </a-col>
          </a-row>
          <br/>
          <a-row :gutter="16">
            <a-col :span="8">
              <a-card class="card scrollable-card" title="Brands" :bordered="false">
                <p v-if="pageData.brands.length < 1" >No Brands found</p>
                <div :style="brandColorClass()" class="circle" v-for="item of pageData.brands" :key="String(item)"><a-tooltip :title="item">{{ returnAcronym(item) }}</a-tooltip></div>
                <!--
                <p>
                  <img style="margin-right: 5px" width="30px" :src="require('../../assets/singleoutleticon/brands/brand_pepsi.png')"/>
                  <img style="margin-right: 5px" width="30px" :src="require('../../assets/singleoutleticon/brands/brand_fanta.png')"/>
                  <img style="margin-right: 5px" width="30px" :src="require('../../assets/singleoutleticon/brands/brand_erdinger.png')"/>
                  <img style="margin-right: 5px" width="30px" :src="require('../../assets/singleoutleticon/brands/brand_heineken.png')"/>
                  <img style="margin-right: 5px" width="30px" :src="require('../../assets/singleoutleticon/brands/brand_pepsi_zero.png')"/>
                  <img style="margin-right: 5px" width="30px" :src="require('../../assets/singleoutleticon/brands/brand_redbull.png')"/>
                  <img style="margin-right: 5px" width="30px" :src="require('../../assets/singleoutleticon/brands/brand_sprite.png')"/>
                  <a-tag v-for="item of pageData.brands" :key="String(item)" style="color:#1990FF; background:#D1E9FF; border: 1px solid #1990FF; margin-top:5px;">{{ returnAcronym(item) }}</a-tag>
                </p>
                -->
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card class="card scrollable-card" title="Manufacturers" :bordered="false">
                <p v-if="pageData.manufacturers.length > 0">
                  <a-tag disabled v-for="item of pageData.manufacturers" :key="String(item)" style="color:#1990FF; background:#D1E9FF; border: 1px solid #1990FF; margin-top:8px; margin-right:8px;">{{ item }}</a-tag>
                </p>
                <p v-else>No Manufacturers found</p>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card class="card scrollable-card" title="Source Links" :bordered="false">
                <p v-if="pageData.actual_source_links.length > 0">
                  <a-tag @click="goToSource(item.platform_url)" disabled v-for="item of pageData.actual_source_links" :key="String(item.platform)" :class="{ 'source-tag-with-link': item.platform_url, 'source-tag-without-link': !item.platform_url }">{{ item.platform }}<img v-if="item.platform_url" style="margin-left: 5px;" :src="require('../../assets/singleoutleticon/arrow_diagonal_up_right_purple.svg')"/></a-tag>
                </p>
                <p v-else>No Source Links found</p>
              </a-card>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <a-divider />
      <a-row class="portfolioinformationrow">
        <a-col :span="8">
          <b class="dash-header-four" style="/* /Text Grey */ color: #5C6075;">Portfolio Information</b>
          </a-col>
        <a-col :span="16">
          <span><a-input placeholder="Search for brand name" style="float: right; width: 200px;background: #FFFFFF;box-shadow: 0px 4px 10px rgba(120, 120, 120, 0.1), 0px 4px 5px rgba(151, 151, 151, 0.1);border-radius: 8px;" @change="onSearch" id="search-input" suffix=""><a-icon slot="prefix" type="search" /></a-input> </span>
          <br/><br/><a-table style="margin-top:16px; overflow: auto; background: #FFFFFF;box-shadow: 0px 4px 20px rgba(40, 80, 155, 0.15);border-radius: 8px;" :scroll="{ y: 300 }" :columns="columns" :data-source="searchData" :pagination="false">
            <span slot="sourceTag" slot-scope="source">
      <a-tag disabled :style="colorClass(source)">
        {{ source }}
      </a-tag>
      </span>
                      <a slot="name" slot-scope="text">{{ text }}</a>
                    </a-table>
        </a-col>
      </a-row>
    </a-row>
    <div style="margin-top: 150px;" v-else-if="!allowAccess" class="failedLoadingPage"><div class="box"><img class="failedPlug" :src="require('../../assets/Connection-Lost.svg')" alt="Connection Error"><br><div class="errorText">You are not allowed to access this dashboard.</div><br></div></div>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard'
import firebase from 'firebase'
import moment from 'moment'
import { db } from '@/firebase'
import { path } from 'ramda'
var CryptoJS = require('crypto-js')

const columns = [
  {
    title: 'Brand',
    dataIndex: 'brand',
    key: 'brand',
    scopedSlots: { customRender: 'brand' },
    width: 100,
    sorter: (a, b) => a.brand.localeCompare(b.brand)
  },
  {
    title: 'Sub Brand',
    dataIndex: 'subbrand',
    key: 'subbrand',
    width: 120,
    sorter: (a, b) => a.subbrand.localeCompare(b.subbrand)
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer',
    key: 'manufacturer',
    ellipsis: false,
    width: 140,
    sorter: (a, b) => a.manufacturer.localeCompare(b.manufacturer)
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    ellipsis: true,
    width: 90,
    sorter: (a, b) => a.category.localeCompare(b.category)
  },
  {
    title: 'Source',
    dataIndex: 'source',
    key: 'source',
    ellipsis: false,
    scopedSlots: { customRender: 'sourceTag' },
    width: 100,
    sorter: (a, b) => a.source.localeCompare(b.source)
  },
  {
    title: 'Currency',
    dataIndex: 'currency',
    key: 'currency',
    ellipsis: false,
    width: 110,
    sorter: (a, b) => a.currency.localeCompare(b.currency)
  },
  {
    title: 'Price',
    dataIndex: 'price',
    key: 'price',
    ellipsis: false,
    width: 70
  },
  {
    title: 'Volume',
    dataIndex: 'volume',
    key: 'volume',
    ellipsis: true,
    width: 80
  }
]

const tableData = []
const searchData = []

export default {
  name: 'SingleOutletPage',
  props: {
    projectId: {
      type: String,
      default: ''
    },
    dashboardId: {
      type: String,
      default: ''
    },
    outletId: {
      type: String,
      default: ''
    },
    isComponent: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // For authorization checking
      dashmoteUser: false,
      allowAccess: true,
      // Pagewide values
      tableData,
      searchData,
      columns,
      visible: false,
      overlay: false,
      token: '',
      pageData: {},
      // Config for the brand portfolio chart
      config: {
        width: 4000,
        height: 4000,
        appendPadding: 0,
        angleField: 'value',
        colorField: 'type',
        radius: 0.7,
        innerRadius: 0.64,
        meta: {
          value: {
            formatter: (v) => ` ${v}%`
          }
        },
        label: {
          type: 'outer',
          offset: '-50%',
          autoRotate: false,
          style: { textAlign: 'center' },
          formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%`
        },
        legend: {
          offsetX: -85,
          position: 'right',
          width: 1000
        },
        statistic: {
          title: {
            offsetY: -0,
            formatter: function formatter (datum) {
              return datum ? datum.type : 'Total'
            }
          },
          content: {
            offsetY: -0
          }
        },
        interactions: [
          { type: 'element-selected' },
          { type: 'element-active' },
          {
            type: 'pie-statistic-active',
            cfg: {
              start: [
                { trigger: 'element:mouseenter', action: 'pie-statistic:change' },
                { trigger: 'legend-item:mouseenter', action: 'pie-statistic:change' }
              ],
              end: [
                { trigger: 'element:mouseleave', action: 'pie-statistic:reset' },
                { trigger: 'legend-item:mouseleave', action: 'pie-statistic:reset' }
              ]
            }
          }
        ],
        data: []
      }
    }
  },
  methods: {
    getData (data) {
      const self = this
      const correctedReviewScore = data.outlet.outlet_review_score / 2

      // Remove questionmarks from the address
      data.outlet.outlet_address = data.outlet.outlet_address.replace(/\?/g, '')

      const mapEmbedsrc = 'https://www.google.com/maps/embed/v1/place?key=AIzaSyBTQPZuj8APTRDCD1QIK2SJRGurL6aKJqc&q=' + data.outlet.outlet_address

      this.pageData = {
        outlet_name: data.outlet.outlet_name,
        outlet_review_score: correctedReviewScore,
        outlet_reviews_nr: data.outlet.outlet_reviews,
        outlet_price: this.formatPriceLevel(data.outlet.price_level),
        outlet_city: data.outlet.outlet_city,
        outlet_types: data.outlet.outlet_types,
        franchise: data.outlet.franchise,
        outlet_id: data.outlet.outlet_dashmote_id,
        outlet_address: data.outlet.outlet_address,
        phone_numbers: data.outlet.phone_numbers,
        website_urls: data.outlet.website_urls,
        opening_hours: data.outlet.opening_hours,
        cuisine_types: data.outlet.cuisine_types,
        delivery_platforms: data.outlet.delivery_platforms,
        portfolio: data.outlet.portfolio,
        // Placeholders
        brands: [],
        manufacturers: [],
        // Map ebed url
        map_embed_src: mapEmbedsrc
      }

      // This part is in Beta and should be improved with all our delivery platforms
      var actualDeliveryPlatforms = []
      var actualSourceLinks = []
      for (var k = 0; k < data.outlet.delivery_platforms.length; k++) {
        if (data.outlet.delivery_platforms[k].platform === 'Googlemaps' || data.outlet.delivery_platforms[k].platform === 'Tripadvisor') {
          actualSourceLinks.push(data.outlet.delivery_platforms[k])
        } else {
          actualDeliveryPlatforms.push(data.outlet.delivery_platforms[k])
        }
      }
      this.pageData.actual_delivery_platforms = actualDeliveryPlatforms
      this.pageData.actual_source_links = actualSourceLinks

      // Format the price column
      var key
      for (key in data.outlet.portfolio) {
        let actualPrice, actualVolume
        if (data.outlet.portfolio[key].price) {
          actualPrice = data.outlet.portfolio[key].price
        } else {
          actualPrice = '-'
        }

        // Format the volume column
        if (data.outlet.portfolio[key].volume === 'unknown' || data.outlet.portfolio[key].volume === '') {
          actualVolume = '-'
        } else {
          actualVolume = data.outlet.portfolio[key].volume
        }

        // populate the portfolio data
        this.tableData.push({
          brand: data.outlet.portfolio[key].brand,
          subbrand: data.outlet.portfolio[key].subbrand,
          manufacturer: data.outlet.portfolio[key].manufacturer,
          category: data.outlet.portfolio[key].beverage_category_level1,
          source: data.outlet.portfolio[key].source,
          currency: data.outlet.portfolio[key].currency,
          price: actualPrice,
          volume: actualVolume,
          key: key
        })
      }
      this.searchData = this.tableData

      // MANUFACTURERS
      // Get the manufacturers from portfolio and set the unique ones to pageData
      const allManufacturersFromPortfolio = this.pageData.portfolio.map(x => x.manufacturer)
      var uniqueManufacturers = allManufacturersFromPortfolio.filter((v, i, a) => a.indexOf(v) === i)
      this.pageData.manufacturers = uniqueManufacturers

      // BRANDS
      // Get the brands from portfolio
      const allBrandsFromPortfolio = this.pageData.portfolio.map(x => x.brand)

      // Count the amount of brands in portfolio
      var brandCounts = {}
      allBrandsFromPortfolio.forEach(function (x) { brandCounts[x] = (brandCounts[x] || 0) + 1 })

      // Create items array
      var items = Object.keys(brandCounts).map(function (key) {
        return [key, brandCounts[key]]
      })

      // Sort the array based on the second element
      items.sort(function (first, second) {
        return second[1] - first[1]
      })

      // Create a new array with only the first 5 items
      // console.log(items.slice(0, 5))
      var pieChartCounts = {}

      var mainItems = items.slice(0, 3)
      for (var j = 0; j < mainItems.length; j++) {
        pieChartCounts[mainItems[j][0]] = mainItems[j][1]
      }
      if (items.length > 3) {
        var others = items.slice(3, items.length)
        var othersCount = 0
        for (var i = 0; i < others.length; i++) {
          othersCount = othersCount + others[i][1]
        }
        pieChartCounts.others = othersCount
      }

      // Fix this -> Rounding can result in more than 100%
      // Set the Brand Portfolio data
      Object.keys(pieChartCounts).map(function (key, index) {
        pieChartCounts[key] = Math.round((pieChartCounts[key] / allBrandsFromPortfolio.length) * 100)
        self.config.data.push({ type: key, value: pieChartCounts[key] })
      })

      // Make the brands unique
      var uniqueBrands = allBrandsFromPortfolio.filter((v, i, a) => a.indexOf(v) === i)
      this.pageData.brands = uniqueBrands
    },
    formatPriceLevel (priceLevel) {
      let price = ''
      switch (priceLevel) {
        case 1:
          price = '€'
          break
        case 2:
          price = '€€'
          break
        case 3:
          price = '€€€'
          break
        case 4:
          price = '€€€€'
          break
        default:
          // Default case
      }
      return price
    },
    returnAcronym (str) {
      var matches = str.match(/\b(\w)/g)
      var acronym = matches.join('')
      return acronym
    },
    colorClass (source) {
      if (source === 'Googlemaps') {
        return {
          color: '#1990FF',
          background: '#D1E9FF',
          border: '1px solid #1990FF'
        }
      } else {
        return {
          color: '#B37FEB',
          background: '#F0E5FB',
          border: '1px solid #B37FEB'
        }
      }
    },
    getDecryptedEmail () {
      // Check if there is already an userEmail stored
      if (localStorage.getItem('userEmail')) {
        const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(localStorage.getItem('userEmail')), process.env.VUE_APP_AES_PASSPHRASE).toString()
        localStorage.setItem('userReference', ciphertext)
        localStorage.removeItem('userEmail')
      }
      // decrypt item
      const bytes = CryptoJS.AES.decrypt(localStorage.getItem('userReference'), process.env.VUE_APP_AES_PASSPHRASE)
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
      // console.log(decryptedData)
      return decryptedData
    },
    onSearch (value) {
      // console.log(value)
      this.searchData = this.tableData
      this.searchData = this.search(this.searchData, { brand: document.getElementById('search-input').value })
    },
    search (dataSource, argumentObj) {
      let result = dataSource
      let dataClone = dataSource
      for (const argu in argumentObj) {
        if (argumentObj[argu].length > 0) {
          result = dataClone.filter(d => {
            if (d[argu]) {
              // console.log(d[argu])
              return d[argu].toLowerCase().indexOf(argumentObj[argu].toLowerCase()) > -1
            }
          })
          dataClone = result
        }
      }
      return result
    },
    goMainPage () {
      //
      // this.$router.go(-1)
      this.$emit('go-back')
    },
    openShareModal () {
      this.visible = true
    },
    copyPageURL () {
      copy(process.env.VUE_APP_PROJECT_URL + `outlet/${this.projectId}/${this.dashboardId}/${this.outletId}`)
      this.$message.success('Copied page URL to your clipboard', 1)

      // Tracking
      const sharedURL = process.env.VUE_APP_PROJECT_URL + `outlet/${this.projectId}/${this.dashboardId}/${this.outletId}`
      this.$mixpanel.track('Copied page URL to clipboard', { pageURL: sharedURL })
      //
    },
    copyGoogleMapsURL () {
      copy('https://www.google.com/maps/search/?api=1&query=' + this.pageData.outlet_name + '+' + this.pageData.outlet_address)
      this.$message.success('Copied Google Maps URL to your clipboard', 1)

      // Tracking
      const sharedMapsURL = 'https://www.google.com/maps/search/?api=1&query=' + this.pageData.outlet_name + '+' + this.pageData.outlet_address
      this.$mixpanel.track('Copied Google Maps URL to clipboard', { mapsURL: sharedMapsURL })
      //
    },
    copyOutletID () {
      copy(this.pageData.outlet_id)
      this.$message.success('Copied Outlet ID to your clipboard', 1)

      // Tracking
      this.$mixpanel.track('Copied Outlet ID to clipboard', { outletId: this.pageData.outlet_id })
      //
    },
    goToSource (sourceURL) {
      if (sourceURL) {
        if (sourceURL.startsWith('http://') || sourceURL.startsWith('https://')) {
          window.open(sourceURL, '_blank')
          // Tracking
          this.$mixpanel.track('Opened Source Link', { linkURL: sourceURL })
        } else {
          this.$message.error('Platform link is currently unavailable.', 1)
          console.log('Platform link is currently unavailable.')
        }
      } else {
        console.log('Platform link is currently unavailable.')
      }
    },

    getColor () {
      return 'hsl(' + 360 * Math.random() + ',' +
             (25 + 70 * Math.random()) + '%,' +
             (85 + 10 * Math.random()) + '%)'
    },
    brandColorClass () {
      return {
        color: '#1990FF',
        background: this.getColor()
      }
    },
    getSingleOutletPageData () {
      let data = {}
      // Package the data for the email
      if (this.isComponent) {
        data = {
          projectId: this.projectId,
          dashboardId: this.dashboardId,
          outletId: this.outletId
        }
      } else {
        data = {
          projectId: this.$route.params.projectId,
          dashboardId: this.$route.params.dashboardId,
          outletId: this.$route.params.outletId
        }
      }

      const getSingleOutletPage = firebase.functions().httpsCallable('getSingleOutletPage')
      getSingleOutletPage(data).then((response) => {
        this.getData(response.data)
      })
    },
    getOpeningTimeToday (openingHours) {
      const today = moment().day()
      let openingTimeToday
      switch (today) {
        case 1:
          openingTimeToday = openingHours.opening_hrs_mon
          break
        case 2:
          openingTimeToday = openingHours.opening_hrs_tue
          break
        case 3:
          openingTimeToday = openingHours.opening_hrs_wed
          break
        case 4:
          openingTimeToday = openingHours.opening_hrs_thu
          break
        case 5:
          openingTimeToday = openingHours.opening_hrs_fri
          break
        case 6:
          openingTimeToday = openingHours.opening_hrs_sat
          break
        case 7:
          openingTimeToday = openingHours.opening_hrs_sun
          break
        default:
          // code block
      }
      return 'Today: ' + openingTimeToday
    },
    checkPermission () {
      // Check here if the user has permission to access this dashboard.
      const self = this
      const getUserByEmail = firebase
        .functions()
        .httpsCallable('getUserByEmail')
      getUserByEmail(self.getDecryptedEmail())
        .then((response) => {
          db.ref(`users/${path(['data', 'uid'], response)}/projects/${self.projectId}/`)
            .once('value', (snapshot) => {
              if (snapshot.val()) {
                self.allowAccess = true
              } else {
                if (this.dashmoteUser) {
                  self.allowAccess = true
                } else {
                  self.allowAccess = false
                }
              }
            })
        })
    },
    checkIsDashmoteUser () {
      const localemail = this.getDecryptedEmail()
      const emailPostfix = localemail.split('@')[1]
      if (emailPostfix === 'dashmote.com') {
        this.dashmoteUser = true
      } else {
        this.dashmoteUser = false
      }
    }
  },
  mounted () {
    // Check for authorization
    this.checkIsDashmoteUser()
    this.checkPermission()

    // Retreive the data for the single outlet page
    this.getSingleOutletPageData()
  },
  created () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../Projects/Projects.css';
@import './DetailPage.css';
@import './SingleOutletPage.css';

@media print {
      .header {
        zoom: 60%;
      }
      .outletcontent {
        margin-top: 2% !important;
        margin-left: 2% !important;
        margin-right: 2% !important;
        zoom: 60%;
      }
    }
</style>
